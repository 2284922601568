<template>

    <form @submit.prevent="onSubmit" @keypress.enter.prevent="onSubmit" method="post" accept-charset="UTF-8">

        <input ref="_id" type="hidden" name="id" value="">


        <InputText
            name="code"
            type="text"
            v-model="code"
            placeholder="Code"
            label="Code"
            :error="validationErrors['code'] ? validationErrors['code'] : ''"
        />

        <InputText
            name="new-password"
            type="password"
            v-model="newPassword"
            placeholder="Nouveau mot de passe"
            label="Nouveau mot de passe"
            :error="validationErrors['newPassword'] ? validationErrors['newPassword'] : ''"
        />

        <transition name="anim-text">
            <p class="mt-4 error" v-for="error in $store.state.user.errors">{{error}}</p>
        </transition>

    </form>
</template>

<script>
import InputText from 'templates/objects/InputText';
import axios from 'axios';
import apiActions from 'config/api-actions';
import { ROUTES } from 'config/routes';
import { isEmpty } from 'services/utils';
import types from 'store/types';
import { validateErrorsForm } from 'services/validation';


export default {
    name: 'ResetPasswordForm',
    components: {
        InputText,
    },
    data() {
        return {
            validationErrors: {},
            email: '',
            code: '',
            newPassword: '',
            id: '',
        };
    },
    created() {
        const query = this.$route.query || null;

        // grab data from query (GET PARAMS)
        if (query) {
            if (typeof query.email !== 'undefined') {
                this.email = query.email;
            }
            if (typeof query.code !== 'undefined') {
                this.code = query.code;
            }
            if (typeof query.id !== 'undefined') {
                this.id = query.id;
            }
        }
    },
    mounted() {
    // if the id is empty
        if (isEmpty(this.id)) {
            // can we grab it from the input field ?
            if (!isEmpty(this.$refs._id.value)) {
                this.id = this.$refs._id.value;
            } else {
                // Input field was empty so we need to fetch it
                // Should fetch the uid from the email
                axios.post(apiActions.user.getUidFromEmail, { email: this.email })
                    .then((data) => {
                        if (data.success) {
                            this.id = data.uid;
                            this.$refs._id.value = data.uid;
                        } else {
                            // go back we need the id, may need to clean this up because it change
                            // TODO: check email because going here...
                            this.$router.push(ROUTES.MODAL.AUTH.FORGOT_PASSWORD);
                            this.$store.commit(types.USER_ERROR, ['Le courriel n\'existe pas']);
                        }
                    })
                    .catch((err) => {
                        // go back we need the id
                        this.$router.push(ROUTES.MODAL.AUTH.FORGOT_PASSWORD);
                        this.$store.commit(types.USER_ERROR, ['Une erreur s\'est produite']);
                    });
            }
        }
    },
    methods: {
        onSubmit() {
            this.$emit('submit');
        },
        submitSetPasswordForm() {
            return new Promise((resolve, reject) => {
                if (isEmpty(this.id)) {
                    reject();
                    return;// need to have the id
                }

                const data = {
                    code: this.code,
                    newPassword: this.newPassword,
                    id: this.id,
                };
                const validationErrors = validateErrorsForm(data, ['newPassword', 'code']);

                // reset errors
                this.validationErrors = validationErrors;

                // Check for validation errors before
                if (!isEmpty(validationErrors)) {
                    reject();
                    return;// don't make the ajax call
                }

                this.$store.dispatch(types.RESET_PASSWORD, data)
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        // error
                        reject();
                    });
            });
        },
    },
};
</script>
