<template>
    <form class="login" @submit.prevent="onSubmit" @keypress.enter.prevent="onSubmit" method="post"
          accept-charset="UTF-8">

        <InputText
            name="email"
            type="email"
            v-model="email"
            placeholder="Adresse courriel"
            label="Adresse courriel"
            :error="validationErrors['email'] ? validationErrors['email'] : ''"
        />

        <InputText
            name="password"
            type="password"
            v-model="password"
            placeholder="Mot de passe"
            label="Mot de passe"
            :error="validationErrors['password'] ? validationErrors['password'] : ''"
        />

        <transition name="anim-text">
            <p class="mt-4 error" v-for="error in $store.state.user.errors">{{error}}</p>
        </transition>

    </form>
</template>
<script>
import InputText from 'templates/objects/InputText';

import { isEmpty } from 'services/utils';
import types from 'store/types';
import { validateErrorsForm } from 'services/validation';


// Register component
export default {
    name: 'login-form',
    components: {
        InputText,
    },
    data() {
        return {
            validationErrors: {},
            email: '',
            password: '',
        };
    },
    methods: {
        onSubmit() {
            this.$emit('submit');
        },
        submitLoginForm() {
            return new Promise((resolve, reject) => {
                const data = {
                    email: this.email,
                    password: this.password,
                };
                const validationErrors = validateErrorsForm(data, ['email', 'password']);

                // reset errors
                this.validationErrors = validationErrors;

                // Check for validation errors before
                if (!isEmpty(validationErrors)) {
                    reject();
                    return;// don't make the ajax call
                }


                // for craft
                data.loginName = this.email;

                this.$store.dispatch(types.LOGIN_USER, data)
                    .then(() => {
                        resolve();
                    })
                    .catch((err) => {
                        reject();
                    });
            });
        },
    },
};

</script>
