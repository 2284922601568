<template>
    <div class="o-info-window">
        <div class="o-info-window__title" :class="{'py-2 pt-4': showModify }">
            <h4 class="text-center text-t3" v-if="showModify">{{title}}</h4>
            <UpdateLocationName v-else></UpdateLocationName>
        </div>
        <div class="o-info-window__content" :class="{'with-border': !isEmpty(title)}">
            <address class="c-listing__address | flex items-start">
                <Icon
                    icon="picker"
                    size="xs"
                    class="mr-3"
                ></Icon>
                <p class="text-t4" v-html="address.split(',').join(',<br>')"></p>
            </address>
        </div>
    </div>
</template>

<script>
import Icon from 'templates/objects/Icon';
import UpdateLocationName from "templates/objects/UpdateLocationName";
import {ROUTES} from "config/routes";
import {isEmpty} from "services/utils";

export default {
    name: 'InfoWindow',
    components: {UpdateLocationName, Icon},
    computed: {
        canModifyTitle() {
            return this.$route.name === 'result'
        },
        showModify() {
            return !this.canModifyTitle && !isEmpty(this.title)
        }
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        address: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss">
    .o-info-window {
        @apply bg-light font-heading w-full h-full;

        * {
            @apply font-heading;
        }
    }

    .o-info-window__title {
        @apply px-4 text-body;

        .o-input-text {
            font-size: 17px;
            line-height: 1.2;
            font-weight: 700;
        }

    }

    .o-info-window__content {
        @apply px-8 py-6;


        &.with-border {
            @apply px-4 py-2 pb-6;
            border-top: 1px solid $color-grey-light;
        }

        .c-listing__address {
            font-style: normal;
        }
    }

    /*   .gm-style-iw-d {
           overflow: visible !important;
       }

       .gm-style .gm-style-iw-t::after, .gm-style.gm-style-iw-t::after {
           display: none !important;
       }

       .gm-style-iw.gm-style-iw-c {
           padding: 0 !important;
           box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06) !important;

           .gm-ui-hover-effect {
               display: none !important;
           }
       }
       */

</style>
