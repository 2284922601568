import axios from 'axios';
import types from 'store/types';
import Vue from 'vue';

// initial state, with craft backend or empty
const initData = JSON.parse(JSON.stringify(window.__initialData__));
const state = {
    routes: initData.routes,
    pages: initData.pages || {},
};

// getters, make function easy to access by vue
// instead
const getters = {};

// actions
const actions = {
    [types.PRELOAD_PAGES](store, pagesIds) {
        pagesIds.forEach((id) => {
            store.dispatch(types.LOAD_PAGE, id)
                .catch((err) => {
                    console.log(err);
                });
        });
    },
    [types.LOAD_PAGE](store, pageId) {
        if (typeof store.state.pages[pageId] !== 'undefined') {
            return Promise.resolve(store.state.pages[pageId]);
        }

        return new Promise((resolve, reject) => {

            axios.get(`/actions/module/page/get-page?id=${encodeURI(pageId)}`)
                .then((data) => {
                    if (typeof data.page === 'undefined') {
                        reject();
                        return;
                    }

                    store.commit(types.LOAD_PAGE, data.page);
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

};

// mutations
const mutations = {
    [types.LOAD_PAGE](state, pageData) {
        Vue.set(state.pages, pageData.id, pageData);
    },
};

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
};
