// simple mapping of url

export const ROUTES = {
    HOME: '/',

    // Admin parent
    ADMIN: {
        ROOT: '/mon-compte',
        _DASHBOARD: '/',
        SETTINGS: '/mon-compte/reglages',
        _SETTINGS: 'reglages',
    },


    // Quiz parent
    QUIZ: '/quiz/:quizUid',
    QUESTION: '/quiz/:quizUid/question/:questionSlug',
    _QUESTION: 'question/:questionSlug',
    PRINCIPE: '/quiz/:quizUid/principe/:uid',
    _PRINCIPE: 'principe/:uid',
    _RESULT: '/',

    // Modals
    MODAL: {
        CREATE_QUIZ: '/nouveau',
        AUTH: {
            LOGIN: '/connexion',
            REGISTER: '/enregistrement',
            LOGOUT: '/deconnexion',
            FORGOT_PASSWORD: '/pwd/oublie',
            RESET_PASSWORD: '/pwd/nouveau', // need to change on backend side if we change that
            FINISH_GUEST: '/finir-compte',
        },
        WARN: {
            BACK_TO_HOME: '/retour-accueil',
            DELETE_QUIZ: '/supprimer-quiz',
            DELETE_ACCOUNT: '/supprimer-compte',
        },
        SUCCESS: {
            SETTINGS: '/parametres-actualises',
        },
        SKIP_QUESTIONS: '/skip-questions',
    },

    // Error
    ERROR: '/404',
};

export const DYNAMIC_ROUTES = {
    QUESTION: (quizUid, slug) => ROUTES.QUESTION.replace(':quizUid', quizUid).replace(':questionSlug', slug),
    PRINCIPE: (quizUid, uid) => ROUTES.PRINCIPEreplace(':quizUid', quizUid).replace(':uid', uid),
    RESULT: quizUid => ROUTES.QUIZ.replace(':quizUid', quizUid),
};
