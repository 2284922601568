import types from 'store/types';
// initial state, with craft backend or empty
const state = {
    isInitialLoading: true,
    loaderName: [],
};

// getters, make function easy to access by vue
// instead
const getters = {};

// actions
const actions = {
    [types.START_LOADER](store, loaderName) {
        store.commit(types.START_LOADER, loaderName);
    },
    [types.STOP_LOADER](store, loaderName) {
        store.commit(types.STOP_LOADER, loaderName);
    },
    [types.STOP_ALL_LOADER](store) {
        store.commit(types.STOP_ALL_LOADER);
    },
};

// mutations
const mutations = {
    [types.STOP_LOADER](state, loaderName) {
        const loaders = [...state.loaderName];
        const idx = loaders.findIndex(loader => loader === loaderName);
        if (idx === -1) return;
        loaders.splice(idx, 1);
        state.loaderName = loaders;
    },
    [types.STOP_ALL_LOADER](state) {
        state.isInitialLoading = false;
        state.loaderName = [];
    },
    [types.START_LOADER](state, loaderName) {
        const loaders = [...state.loaderName];
        const idx = loaders.findIndex(loader => loader === loaderName);
        if (idx > -1) return; // loader already there, dont add twice
        state.loaderName.push(loaderName);
    },
    [types.STOP_INITIAL_LOADER](state) {
        state.isInitialLoading = false;
    },
};

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
};
