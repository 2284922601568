export default {

    // Page state
    LOAD_PAGE: 'LOAD_PAGE',
    PRELOAD_PAGES: 'PRELOAD_PAGES',
    ROUTE_CHANGED: 'ROUTE_CHANGED',

    // General Auth handling
    GET_CURRENT_USER: 'GET_CURRENT_USER',
    LOGIN_USER: 'LOGIN_USER',
    REGISTER_USER: 'REGISTER_USER',
    REGISTER_GUEST_USER: 'REGISTER_GUEST_USER',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    RESET_PASSWORD: 'RESET_PASSWORD',
    LOGOUT_USER: 'LOGOUT_USER',
    FINISH_GUEST: 'FINISH_GUEST',
    USER_ERROR: 'USER_ERROR',
    DELETE_ACCOUNT: 'DELETE_ACCOUNT',

    // User Stuff
    MODIFY_USER: 'MODIFY_USER',
    FETCH_CURRENT_USER_DATA: 'FETCH_CURRENT_USER_DATA',
    FETCH_INITIAL_DATA: 'FETCH_INITIAL_DATA',
    UPDATE_MERGE_QUIZ: 'UPDATE_MERGE_QUIZ',

    // Loader
    START_LOADER: 'START_LOADER',
    STOP_LOADER: 'STOP_LOADER',
    STOP_INITIAL_LOADER: 'STOP_INITIAL_LOADER',
    STOP_ALL_LOADER: 'STOP_ALL_LOADER',

    SET_EMAIL: 'SET_EMAIL',
    SET_EMAIL_EXIST: 'SET_EMAIL_EXIST',
    SET_EMAIL_GUEST: 'SET_EMAIL_GUEST',
    SET_PROGRESS: 'SET_PROGRESS',


    SET_QUIZ_CREATION_TEMP_LOCATION: 'SET_QUIZ_CREATION_TEMP_LOCATION',

    // Quiz
    INIT_QUIZ: 'INIT_QUIZ',
    UPDATE_QUIZ: 'UPDATE_QUIZ',
    SELECT_ANSWER: 'SELECT_ANSWER',
    DELETE_QUIZ: 'DELETE_QUIZ',
};
