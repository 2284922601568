<template>
    <div class="p-forgot-password">
        <LoadingContainer loaderName="forgot-password">
            <h1 class="t-t2">Réinitialiser votre mot de passe</h1>
            <div class="c-form">
                <ForgotPasswordForm
                    ref="forgotPasswordForm"
                    @submit="submitForgotPassword"
                />
                <div class="c-form__btns">
                    <Btn
                        label="Annuler"
                        @click="goBack"
                    />
                    <Btn
                        label="Soumettre"
                        bgColor="yellow"
                        class="c-form__submit"
                        @click="submitForgotPassword"
                    />
                </div>
            </div>
        </LoadingContainer>
    </div>
</template>
<script>
import types from 'store/types';
import { ROUTES } from 'config/routes';
import Btn from 'templates/objects/Btn';
import ForgotPasswordForm from 'templates/modals/auth/forms/ForgotPasswordForm';
import LoadingContainer from 'templates/containers/LoadingContainer';
import Link from 'templates/objects/Link';

export default {
    name: 'ForgotPassword',
    components: {
        Link, LoadingContainer, ForgotPasswordForm, Btn,
    },
    created() {
        if (this.$store.state.user.currentUser !== null) {
            this.$store.dispatch(types.LOGOUT_USER, false);
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        submitForgotPassword(event) {
            if (event) event.preventDefault();
            this.$refs.forgotPasswordForm.submitForgotPasswordForm()
                .then((email) => {
                    this.$router.push({
                        path: ROUTES.MODAL.AUTH.RESET_PASSWORD,
                        query: {
                            email,
                        },
                    });
                })
                .catch(() => {
                    // error
                });
        },
    },
};

</script>
