<template>
    <a
        v-if="type !== 'within'"
        :title="label"
        :href="href"
        :style="bgColor ? `--color-bg: ${bgColor.color}` : ''"
        :class="[
            simple ? 'c-btn--simple' : '',
            size ? `c-btn--${size}` : ''
        ]"
        class="c-btn"
        target="_blank"
        rel="noopener"
    >
        <Icon
            v-if="iconBefore"
            class="c-btn__icon"
            :icon="iconBefore"
        />
        <span class="c-btn__label">
            <slot>{{ label }}</slot>
        </span>
        <Icon
            v-if="iconAfter"
            class="c-btn__icon"
            :icon="iconAfter"
        />
    </a>
    <router-link
        v-else
        :to="href"
        :style="bgColor ? `--color-bg: ${bgColor.color}` : ''"
        :class="[
            simple ? 'c-btn--simple' : '',
            size ? `c-btn--${size}` : ''
        ]"
        class="c-btn"
        exact
    >
        <Icon
            v-if="iconBefore"
            class="c-btn__icon"
            :icon="iconBefore"
        />
        <span class="c-btn__label">
            <slot>{{ label }}</slot>
        </span>
        <Icon
            v-if="iconAfter"
            class="c-btn__icon"
            :icon="iconAfter"
        />
    </router-link>
</template>

<script>
import Icon from 'templates/objects/Icon';

export default {
    name: 'Link',
    components: {
        Icon,
    },
    props: {
        label: {
            type: String,
            default: 'Button Label',
        },
        href: {
            type: String,
            default: '#',
        },
        iconBefore: {
            type: String,
            default: '',
        },
        iconAfter: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'within',
        },
        bgColor: {
            type: Object,
        },
        simple: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: '',
        },
    },
};
</script>
