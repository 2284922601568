<template>
    <div
        :is="tag"
        @click.stop="onClick"
        class="c-btn"
        :class="[
            simple ? 'c-btn--simple' : '',
            bgColor ? `bg-${bgColor}` : '',
            size ? `c-btn--${size}` : '',
            disabled ? 'is-disabled' : ''
        ]"
    >
        <Icon
            v-if="iconBefore"
            class="c-btn__icon"
            :icon="iconBefore"
        />
        <span class="c-btn__label">
            <slot>{{ label }}</slot>
        </span>

        <Icon
            v-if="iconAfter"
            class="c-btn__icon"
            :icon="iconAfter"
        />

    </div>
</template>

<script>
import Icon from 'templates/objects/Icon';

export default {
    name: 'Btn',
    components: {
        Icon,
    },
    props: {
        tag: {
            type: String,
            default: 'button',
        },
        label: {
            type: String,
            default: 'Button Label',
        },
        iconBefore: {
            type: String,
            default: '',
        },
        iconAfter: {
            type: String,
            default: '',
        },
        bgColor: {
            type: String,
            default: '',
        },
        simple: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onClick() {
            this.$emit('click');
        },
    },
};
</script>
