<template>
    <div
        class="c-box"
        :class="[
            size ? `c-box--${size}` : '',
            reveal ? `js-reveal` : ''
        ]"
        :style="color && color.light ? `--color-bg: ${color.light}` : ''"
        v-view.once
    >
        <div
            v-if="hasTitle"
            class="c-box__title"
        >
            <slot name="title">
                <Rounded v-if="title || icon">
                    <Icon
                        v-if="icon"
                        :icon="icon"
                        :style="color && color.main ? `--color-bg: ${color.main}` : ''"
                    />
                    <h3
                        v-if="title"
                        class="t-s"
                    >
                        {{ title }}
                    </h3>
                </Rounded>
            </slot>
        </div>
        <div
            v-if="hasOtherTitle"
            class="c-box__other-title"
        >
            <slot name="other-title">
                <Rounded v-if="otherTitle || icon">
                    <Icon
                        v-if="icon"
                        :icon="icon"
                        :style="color && color.main ? `--color-bg: ${color.main}` : ''"
                    />
                    <h3
                        v-if="otherTitle"
                        class="t-s"
                    >
                        {{ otherTitle }}
                    </h3>
                </Rounded>
            </slot>
        </div>

        <div
            class="c-box__inner"
            :class="{ 'c-box__inner--shadow' : shadow }"
        >
            <div
                v-if="header"
                class="c-box__header"
            >
                <h3
                    class="t-uppercase"
                    v-html="header"
                ></h3>
            </div>
            <div class="c-box__content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import Rounded from 'templates/objects/Rounded';
import Icon from 'templates/objects/Icon';

export default {
    name: 'Box',
    components: {
        Rounded,
        Icon,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        otherTitle: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        header: {
            type: String,
            default: '',
        },
        color: {
            type: Object,
            default: () => {
            },
        },
        size: {
            type: String,
            default: '',
        },
        shadow: {
            type: Boolean,
            default: false,
        },
        reveal: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasTitle() {
            return !!this.$slots.title || this.title !== '' || this.icon !== '';
        },
        hasOtherTitle() {
            return !!this.$slots['other-title'] || this.otherTitle !== '';
        },
    },
};
</script>

<style lang="scss">

    .c-box {
        --color-bg: #{$color-light};
        --color-rounded: #{$color-light};

        &.js-reveal {

            &.c-box--center .c-box__title {
                transform: scale(.5) translate(-50%, -50%);
            }

            .c-box__title {
                opacity: 0;
                transform: scale(.5) translate(0, -50%);
            }

            .c-box__inner {
                border-radius: 0;
                opacity: 0;
                transform: translate(0, 2em) scale(1, .8);
                transform-origin: 50% 100%;
            }

            &.view-in {
                transition: all .6s $in-out-sine;

                &.c-box--center .c-box__title {
                    transform: scale(1) translate(-50%, -50%);
                }

                .c-box__title {
                    opacity: 1;
                    transform: scale(1) translate(0, -50%);
                    transition: all .4s $out-expo .3s
                }

                .c-box__inner {
                    border-radius: $radius;
                    opacity: 1;
                    transform: translate(0) scale(1);
                    transition: border-radius .6s $in-sine, opacity .6s $in-out-sine, transform .6s $out-expo;
                }
            }
        }

        .c-box__title {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 1.5em;
            transform: translate(0, -50%);

            .o-rounded {
                background-color: var(--color-rounded);
            }

            & + .c-box__inner {
                margin-top: 4em;
                padding-top: 2em;
            }
        }

        .c-box__other-title {
            z-index: 1;
            position: absolute;
            top: 0;
            right: 1.5em;
            transform: translate(0, -50%);

            .o-rounded {
                background-color: var(--color-bg, #{$color-light});
            }

            & + .c-box__inner {
                margin-top: 4em;
                padding-top: 2em;
            }
        }

        &--red-light {
            --color-bg: #{$color-red-light};
        }

        &--blue-light {
            --color-bg: #{$color-blue-light};
        }

        &--blue-aqua-light {
            --color-bg: #{$color-blue-aqua-light};
        }

        &--green-light {
            --color-bg: #{$color-green-light};
        }

        &--yellow-light {
            --color-bg: #{$color-yellow-light};
        }

        &--grey-light {
            --color-bg: #{$color-grey-light};
            color: $color-grey;
        }

        &--center {
            text-align: center;

            .c-box__title {
                left: 50%;
                white-space: nowrap;
                transform: translate(-50%, -50%);
            }
        }

        &--sm {

            .c-box__title {

                .o-rounded {
                    padding: .4em 1em;
                }

                & + .c-box__inner {
                    margin-top: 2em;
                }
            }

            .c-box__other-title {

                .o-rounded {
                    padding: .4em 1em;
                }

                & + .c-box__inner {
                    margin-top: 2em;
                }
            }

            .c-box__content {
                padding: 1em;
            }

            .c-box__header {
                padding: 1em;

                & + .c-box__content {
                    padding: 1em;
                }
            }
        }
    }

    .c-box__header {
        padding: 1.5em 2em;
        border-bottom: $border-light;
    }

    .c-box__inner {
        background-color: var(--color-bg, #{$color-light});
        border-radius: $radius;

        &--shadow {
            box-shadow: $shadow;
        }
    }

    .c-box__content {
        padding: 2em;
    }

    .c-box__award {
        position: absolute;
        top: -.5em;
        right: -.5em;
    }

</style>
