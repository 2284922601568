import moment from 'moment';

/**
 * Retrieves input data from ' a form and returns it as a JSON object.
 * @param  {HTMLFormControlsCollection} elements  the form elements
 * @return {Object}                               form data as an object literal
 */
export const formToJSON = elements => [].reduce.call(elements, (data, element) => {
    data[element.name] = element.value;
    return data;
}, {});


export const isEmpty = value => (
    value === undefined
    || value === null
    || (typeof value === 'object' && Object.keys(value).length === 0)
    || (typeof value === 'string' && value.trim().length === 0)
);


export const formatDate = date => moment(date).format('LL');


export const hasClass = (elem, name) => (` ${elem.className} `).indexOf(` ${name} `) >= 0;

export const addClass = (elem, name) => {
    if (!elem) return;
    if (!hasClass(elem, name)) {
        elem.className += (elem.className ? ' ' : '') + name;
    }
};

export const toggleClass = (elem, name, force) => {
    if (!elem) return;
    if (force || typeof force === 'undefined' && !hasClass(elem, name)) {
        addClass(elem, name);
    } else {
        removeClass(elem, name);
    }
};

export const removeClass = (elem, name) => {
    if (!elem) return;
    let set = ` ${elem.className} `;

    // Class name may appear multiple times
    while (set.indexOf(` ${name} `) >= 0) {
        set = set.replace(` ${name} `, ' ');
    }

    // Trim for prettiness
    elem.className = typeof set.trim === 'function' ? set.trim() : set.replace(/^\s+|\s+$/g, '');
};


export const isDescendant = (parent, child) => {
    // make array
    let parents = parent;
    if (typeof parent.length === 'undefined') {
        parents = [parent];
    }

    parents = Array.prototype.slice.call(parents);


    let node = child.parentNode;
    while (node != null) {
        if (parents.includes(node)) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
};

export const getClosest = (elem, selector) => {
    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.className.includes(selector)) return elem;
    }
    return null;
};


export const chunk = (arr, n, reverse = false) => {
    // reverse = if 7 items and 2 chunks, first item will have 4 instead of 3

    let chunkLength = Math.max(arr.length / n, 1);
    if (reverse) {
        chunkLength = Math.ceil(chunkLength);
    }

    const chunks = [];
    for (let i = 0; i < n; i++) {
        chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
    }

    return chunks;
};

export const includesAny = (str, substrings) => {
    for (let i = 0; i !== substrings.length; i++) {
        const substring = substrings[i];
        if (str.indexOf(substring) !== -1) {
            return true;
        }
    }
    return false;
};

export const hashString = (str) => {
    let hash = 0;
    let i;
    let
        chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    hash = Math.abs(hash);
    return hash.toString();
};


export const buildQuery = (data) => {
    // If the data is already a string, return it as-is
    if (typeof (data) === 'string') return data;

    // Create a query array to hold the key/value pairs
    const query = [];

    // Loop through the data object
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            // Encode each key and value, concatenate them into a string, and push them to the array
            query.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`);
        }
    }

    // Join each item in the array with a `&` and return the resulting string
    return query.join('&');
};


export const scrollToTop = () => new Promise((resolve, reject) => {
    const duration = (window.scrollY * 0.6 / (document.body.clientHeight - window.innerHeight) + 0.2);
    TweenLite.to(window, duration, {
        scrollTo: {
            y: 0,
            autoKill: false,
        },
        ease: Expo.easeInOut,
        onComplete() {
            resolve();
        },
    });
});


export const highestOccurence = (arr, defaultValue = null) => {
    if (arr.length === 0) {
        return defaultValue;
    }
    const modeMap = {};
    let maxEl = arr[0];
    let maxCount = 1;
    let oldMaxEl = null;
    let
        oldMaxCount = 0;
    for (let i = 0; i < arr.length; i++) {
        const el = arr[i];
        if (typeof modeMap[el] === 'undefined') {
            modeMap[el] = 1;
        } else {
            modeMap[el] = modeMap[el] += 1;
        }
        if (modeMap[el] >= maxCount) {
            oldMaxEl = maxEl;
            oldMaxCount = maxCount;
            maxEl = el;
            maxCount = modeMap[el];
        }
    }

    // retunrn default value if its equal between two values
    if (defaultValue) {
        if (maxCount === oldMaxCount) {
            if (maxEl === defaultValue || oldMaxEl === defaultValue) {
                return defaultValue;
            }
        }
    }
    return maxEl;
};
