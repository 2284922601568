<template>
    <div class="auth-container">
        <transition
            name="anim-fade"
            mode="out-in"
        >
            <Login key="login" v-if="component === ROUTES.MODAL.AUTH.LOGIN"></Login>
            <Register key="register" v-else-if="component === ROUTES.MODAL.AUTH.REGISTER"></Register>
            <Logout key="logout" v-else-if="component === ROUTES.MODAL.AUTH.LOGOUT"></Logout>
            <ResetPassword key="reset" v-else-if="component === ROUTES.MODAL.AUTH.RESET_PASSWORD"></ResetPassword>
            <ForgotPassword key="forgot" v-else-if="component === ROUTES.MODAL.AUTH.FORGOT_PASSWORD"></ForgotPassword>
            <FinishGuest key="finish-guest" v-else-if="component === ROUTES.MODAL.AUTH.FINISH_GUEST"></FinishGuest>
        </transition>
    </div>
</template>
<script>


import Login from 'templates/modals/auth/Login';
import Register from 'templates/modals/auth/Register';
import Logout from 'templates/modals/auth/Logout';
import ResetPassword from 'templates/modals/auth/ResetPassword';
import ForgotPassword from 'templates/modals/auth/ForgotPassword';
import FinishGuest from 'templates/modals/auth/FinishGuest';

export default {
    name: 'Auth',
    components: {
        FinishGuest, ForgotPassword, ResetPassword, Logout, Register, Login,
    },
    computed: {
        component() {
            if (!this.$route.query) return null;
            if (!this.$route.query.modal) return null;
            return this.$route.query.modal;
        },
    },
};
</script>
