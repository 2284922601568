<template>
    <div class="o-loader" :class="`o-loader-size-${size}`">
        <div class="o-loader__inner">
            <i
                v-for="i in [1, 2, 3]"
                :key="i"
                :ref="`icon${i}`"
                class="o-loader__icon o-icon o-icon--tree"
                icon="tree"
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 32" class="svg-tree">
                    <path class="svg-tree__bg"
                          d="M0.1,17.3C0,18,0,18.6,0,19.2C0,26.3,5.6,32,12.5,32S25,26.2,25,19.2c0-0.6,0-1.2-0.1-1.8l0,0c-0.1-0.4-0.1-0.9-0.3-1.3c-0.2-0.9-0.5-1.8-0.9-2.5C21.8,9.1,18.9,5.7,12.4,0C6.7,4.9,3.2,9.4,1.3,13.2c-0.3,0.5-0.5,0.9-0.6,1.4C0.3,15.6,0.2,16.4,0.1,17.3C0.1,17.3,0,17.3,0.1,17.3z"/>
                    <path
                        d="M21.4,17.4l0.1-0.6l-0.2-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.6-0.4-1.2-0.6-1.8c-1.2-3.1-3.1-5.5-7.5-9.3l-0.6-0.5l-0.6,0.5c-4.2,3.7-6.3,6.9-7.3,9c-0.1,0.3-0.3,0.6-0.4,0.8l-0.1,0.2c-0.3,0.7-0.4,1.3-0.5,2l-0.1,0.5l0.1,0.1c0,0.3,0,0.6,0,0.9c0,4.9,3.5,8.9,8.1,9.4v2.2h1.9v-2.2c4.5-0.5,8.1-4.5,8.1-9.4c0-0.3,0-0.6,0-0.9l0.1-0.1L21.4,17.4z M13.3,26.1v-3.8l3.4-3.1l-1.2-1.3l-2.2,2v-8.3h-1.9v5l-2.2-2l-1.2,1.3l3.4,3.2v7c-3.5-0.5-6.2-3.6-6.2-7.4c0-0.4,0-0.7,0.1-1.1v-0.4l0,0c0.1-0.4,0.1-0.7,0.3-1.1l0.1-0.2c0.1-0.2,0.2-0.4,0.3-0.7c0.9-1.9,2.8-4.6,6.3-7.8c3.7,3.3,5.4,5.4,6.4,8.1c0.2,0.4,0.4,1,0.5,1.5c0,0.1,0,0.2,0.1,0.3l0,0l0.1,0.5c0,0.3,0.1,0.6,0.1,0.9C19.6,22.5,16.8,25.7,13.3,26.1z"/>
                </svg>
            </i>
        </div>
    </div>
</template>

<script>

import Icon from 'templates/objects/Icon';

export default {
    name: 'Loader',
    components: {
        Icon,
    },
    props: {
        size: {
            type: String,
            default: 'normal',
        },
    },
};

</script>
