<template>
    <div>
        <div class="o-modal-default__content">
            <div class="t-cms">
                <p>Les changement on été réalisés avec succès !</p>
            </div>
        </div>

        <div
            class="flex justify-between"
        >
            <Link
                iconBefore="arrow-left"
                label="Retour"
            ></Link>
            <Link
                :href="ROUTES.ADMIN.ROOT"
                iconAfter="arrow-right"
                label="Aller au tableau de bord"
                class="c-btn--primary"
            ></Link>
        </div>
    </div>
</template>

<script>

import Link from 'templates/objects/Link';

export default {
    name: 'Settings',
    components: {
        Link,
    },
};
</script>
