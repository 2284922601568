<template>
    <LoadingContainer loaderName="delete-account">
        <div class="o-modal-default__content">
            <h4 class="o-modal-default__title | t-t4">Êtes-vous sûr de vouloir <br>supprimer votre compte ?</h4>
        </div>

        <div
            class="flex justify-between"
        >
            <Link
                @click="goBack"
                label="Annuler"
            ></Link>
            <Btn
                label="Supprimer"
                :simple="true"
                class="c-btn--grey"
                @click="deleteAccount"
            ></Btn>
        </div>
    </LoadingContainer>
</template>

<script>

import Btn from 'templates/objects/Btn';
import Link from 'templates/objects/Link';
import types from 'store/types';
import LoadingContainer from 'templates/containers/LoadingContainer';
import { ROUTES } from 'config/routes';

export default {
    name: 'DeleteAccount',
    components: {
        LoadingContainer,
        Btn,
        Link,
    },
    methods: {
        deleteAccount() {
            this.$store.dispatch(types.DELETE_ACCOUNT, this.$store.state.user.currentUser.id);
            this.$router.push(ROUTES.MODAL.AUTH.LOGOUT);
        },
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>
