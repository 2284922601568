<template>
    <i
        class="o-icon"
        :class="className"
    >
        <svg
            :class="`svg-${icon}`"
        >
            <title v-if="title">{{ title }}</title>
            <use v-bind:xlink:href="url"></use>
        </svg>
    </i>
</template>

<script>
export default {
    name: 'Icon',
    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: '',
        },
    },
    computed: {
        url() {
            return `${window.location.origin}/dist/svg/sprite.svg#svg-${this.icon}`;
        },
        //        url() {
        //            let icon = require(`@/assets/svg/sources/${this.icon}.svg`);
        //            if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        //                icon = icon.default;
        //            }
        //
        //            return icon.url;
        //        },
        className() {
            let name = `o-icon--${this.icon}`;
            name = this.size ? `${name} o-icon--${this.size}` : name;
            return name;
        },
    },
};
</script>
