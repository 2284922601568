<template>
    <div class="o-map-popup">
        <InfoWindow ref="infoWindow" :title="label" :address="address"></InfoWindow>
    </div>
</template>

<script>
import InfoWindow from 'templates/objects/InfoWindow';
import { createPopupClass } from 'services/google-map';

export default {
    name: 'MapPopup',
    components: { InfoWindow },
    props: ['map', 'lat', 'lng', 'label', 'address', 'pan'],
    updated() {
        this.$nextTick(() => {
            this._mounted();
        });
    },
    beforeDestroy() {
        if (this.popup) this.popup.setMap(null);
    },
    mounted() {
        this._mounted();
    },
    methods: {
        _mounted() {
            if (this.popup) this.popup.setMap(null);

            const Popup = createPopupClass();
            this.popup = new Popup(new google.maps.LatLng({ lat: this.lat, lng: this.lng }), this.$el);

            this.$nextTick(() => {
                this.popup.setMap(this.map);
                if (this.pan) {
                    this.panMap();
                }
            });
        },
        panMap() {
            setTimeout(() => {
                if (!this.map) return;
                const center = JSON.parse(JSON.stringify(this.map.getCenter()));
                center.lat += 0.006;
                this.map.panTo(center);
            }, 500);
        },
    },
};

</script>


<style lang="scss">
    .o-map-popup {
        display: none !important;
    }

    .o-map__googlemap .o-map-popup {
        display: block !important;
    }


    .o-map-popup-bubble {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, calc(-100% - 10px));
        background-color: $color-light;
        border-radius: $radius;
        overflow-y: auto;
        box-shadow: $shadow;
    }


    .o-map-popup-bubble-anchor {
        position: absolute;
        width: 100%;
        bottom: 8px;
        left: 0;
    }


    .o-map-popup-container {
        cursor: auto;
        height: 0;
        position: absolute;
        width: 250px;
    }
</style>
