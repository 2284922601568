<template>
    <div>
        <div class="o-modal-default__content">
            <h4 class="o-modal-default__title | t-t4">Êtes-vous sûr de vouloir <br>retourner à l’accueil?</h4>
            <p v-if="$store.getters.isGuest">Une évaluation qui n’est pas terminée ne peut être sauvegardée si vous ne posséder par de compte utilisateur.</p>
            <p v-else>Vous devez terminer votre évaluation pour avoir un résultat et le comparer.</p>
        </div>

        <div
            class="flex justify-between"
        >
            <Link
                :href="ROUTES.HOME"
                iconBefore="arrow-left"
                label="Retour à l'accueil"
                :simple="true"
                class="c-btn--grey"
            ></Link>
            <Link
                v-if="$store.getters.isGuest"
                iconBefore="user"
                label="Inscription"
            ></Link>
            <Link
                v-else
                iconAfter="arrow-right"
                label="Continuer"
            ></Link>
        </div>
    </div>
</template>

<script>

import Link from 'templates/objects/Link';

export default {
    name: 'BackToHome',
    components: {
        Link,
    },
};
</script>
