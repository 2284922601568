<template>
    <form @submit.prevent="onSubmit" @keypress.enter.prevent="onSubmit" method="post" accept-charset="UTF-8">

        <div class="l-grid__row justify-between items-start">
            <div class="w-full md:w-1/2">
                <InputText
                    name="firstName"
                    type="text"
                    v-model="firstName"
                    placeholder="Prénom"
                    label="Prénom"
                    :error="validationErrors['firstName'] ? validationErrors['firstName'] : ''"
                />
            </div>
            <div class="w-full md:w-1/2">
                <InputText
                    name="lastName"
                    type="text"
                    v-model="lastName"
                    placeholder="Nom"
                    label="Nom"
                    :error="validationErrors['lastName'] ? validationErrors['lastName'] : ''"
                />
            </div>
        </div>

        <InputText
            name="password"
            type="password"
            v-model="password"
            placeholder="Mot de passe"
            label="Mot de passe"
            :error="validationErrors['password'] ? validationErrors['password'] : ''"
        />

        <transition name="anim-text">
            <p class="mt-4 error" v-for="error in $store.state.user.errors">{{error}}</p>
        </transition>

    </form>
</template>

<script>
import InputText from 'templates/objects/InputText';

import { ROUTES } from 'config/routes';
import { isEmpty } from 'services/utils';
import { validateErrorsForm } from 'services/validation';
import types from 'store/types';


export default {
    name: 'FinishGuestForm',
    components: {
        InputText,
    },
    data() {
        return {
            validationErrors: {},
            firstName: '',
            lastName: '',
            password: '',
        };
    },
    methods: {
        onSubmit() {
            this.$emit('submit');
        },
        submitFinishGuestForm() {
            return new Promise((resolve, reject) => {
                const data = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    password: this.password,
                };
                const validationErrors = validateErrorsForm(data, ['firstName', 'lastName', 'password']);

                // reset errors
                this.validationErrors = validationErrors;

                // Check for validation errors before
                if (!isEmpty(validationErrors)) {
                    reject();

                    return;// don't make the ajax call
                }


                this.$store.dispatch(types.FINISH_GUEST, data)
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
    },
};
</script>
