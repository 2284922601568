<template>
    <Outdated v-if="browserOutdated"></Outdated>
    <div
        v-else
        id="app"
    >
        <Loader v-if="showInitialLoader"></Loader>
        <div v-else>
            <router-view></router-view>
        </div>

        <Modal
            v-if="!isEmpty(modal)"
            :name="modal.component.name.toLowerCase()"
            :options="modal.options || null"
            :onClose="closeModal"
            :canClose="(typeof modal.canClose !== 'undefined') ? modal.canClose : true"
        >
            <component :is="modal.component"></component>
        </Modal>
    </div>
</template>

<script>
import './assets/scss/styles.scss';
import Modal from 'templates/components/Modal';
import AuthContainer from 'templates/modals/AuthContainer';
import Default from 'templates/modals/Default';
import CreateQuiz from 'templates/modals/CreateQuiz';
import Loader from 'templates/objects/Loader';
import Icon from 'templates/objects/Icon';
import Outdated from 'templates/objects/Outdated';

import { ROUTES } from 'config/routes';
import { isEmpty } from 'services/utils';
import { detect } from 'detect-browser';
import SkipQuestions from 'templates/modals/SkipQuestions';

const browser = detect();

export default {
    name: 'App',
    components: {
        Icon,
        Loader,
        Modal,
        SkipQuestions,
        Outdated,
    },
    data() {
        return {
            prevRoute: null,
            modal: {},
            browserOutdated: false,
        };
    },
    created() {
        // Browser and OS classes
        document.documentElement.classList.add(`is-${browser.os.replace(' ', '-').toLowerCase()}`);
        document.documentElement.classList.add(`is-${browser.name}`);

        if (browser.name == 'ie') {
            this.browserOutdated = true;
            return;
        }

        this.computeModalComponent();
    },
    watch: {
        $route(nextRoute, prevRoute) {
            this.prevRoute = prevRoute;
            this.computeModalComponent();
        },
    },
    computed: {
        showInitialLoader() {
            return (isEmpty(this.$route.meta) && isEmpty(this.$route.meta.showInitialLoader)) && this.$store.state.general.isInitialLoading;
        },
    },
    methods: {
        closeModal() {
            this.modal = {};

            if (this.prevRoute) {
                // go back with no modal query
                this.$router.push({ path: this.$route.path, query: {} });
            } else {
                // go to home
                this.$router.push('/');
            }
        },

        computeModalComponent() {
            if (!this.$route.query || !this.$route.query.modal) {
                this.modal = {};
                return;
            }

            switch (this.$route.query.modal) {
            case ROUTES.MODAL.CREATE_QUIZ:
                this.modal = {
                    component: CreateQuiz,
                };
                break;
            case ROUTES.MODAL.AUTH.LOGIN:
            case ROUTES.MODAL.AUTH.FORGOT_PASSWORD:
            case ROUTES.MODAL.AUTH.RESET_PASSWORD:
            case ROUTES.MODAL.AUTH.REGISTER:
                this.modal = {
                    component: AuthContainer,
                };
                break;
            case ROUTES.MODAL.AUTH.LOGOUT:
                this.modal = {
                    component: AuthContainer,
                    canClose: false,
                };
                break;
            case ROUTES.MODAL.AUTH.FINISH_GUEST:
                this.modal = {
                    component: AuthContainer,
                    canClose: false,
                    options: {
                        showBodyBehind: false,
                    },
                };
                break;
            case ROUTES.MODAL.WARN.DELETE_QUIZ:
            case ROUTES.MODAL.WARN.BACK_TO_HOME:
            case ROUTES.MODAL.WARN.DELETE_ACCOUNT:
                this.modal = {
                    component: Default,
                };
                break;
            case ROUTES.MODAL.SUCCESS.SETTINGS:
                this.modal = {
                    component: Default,
                };
                break;
            case ROUTES.MODAL.SKIP_QUESTIONS:
                this.modal = {
                    component: SkipQuestions,
                    canClose: false,
                };
                break;
            default:
                this.modal = {};
            }
        },
    },
};

</script>
