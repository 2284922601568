<template>
    <form @submit.prevent="onSubmit" @keypress.enter.prevent="onSubmit" method="post" accept-charset="UTF-8">

        <InputText
            name="email"
            type="email"
            v-model="email"
            placeholder="Adresse courriel"
            label="Adresse courriel"
            :error="validationErrors['email'] ? validationErrors['email'] : ''"
        />

        <transition name="anim-text">
            <p class="mt-4 error" v-for="error in $store.state.user.errors">{{error}}</p>
        </transition>

    </form>
</template>
<script>
import InputText from 'templates/objects/InputText';

import { ROUTES } from 'config/routes';
import { isEmpty } from 'services/utils';
import { validateErrorsForm } from 'services/validation';
import types from 'store/types';


export default {
    name: 'ForgotPasswordForm',
    components: {
        InputText,
    },
    data() {
        return {
            validationErrors: {},
            email: '',
        };
    },
    created() {
        const query = this.$route.query || null;

        if (query) {
            if (typeof query.email !== 'undefined') {
                this.email = query.email;
            }
        }
    },
    methods: {
        onSubmit() {
            this.$emit('submit');
        },
        submitForgotPasswordForm() {
            return new Promise((resolve, reject) => {
                const data = {
                    email: this.email,
                };
                const validationErrors = validateErrorsForm(data, ['email']);

                // reset errors
                this.validationErrors = validationErrors;

                // Check for validation errors before
                if (!isEmpty(validationErrors)) {
                    reject();

                    return;// don't make the ajax call
                }

                // for craft
                data.loginName = this.email;

                this.$store.dispatch(types.FORGOT_PASSWORD, data)
                    .then(() => {
                        resolve(this.email);
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
    },
};
</script>
