import Vue from 'vue';
import Router from 'vue-router';
import vueRoutes from 'config/vue-routes';
import { ROUTES } from 'config/routes';
import store from 'store/index';
import types from 'store/types';
import { isEmpty, scrollToTop } from 'services/utils';
import merge from 'node_modules/lodash/merge';

// Vue Router
Vue.use(Router);

// handle scroll with our code, not the default browser setup or vue.js
if (typeof window.history.scrollRestoration !== 'undefined') {
    window.history.scrollRestoration = 'manual';
}

const router = new Router({
    base: '/',
    routes: vueRoutes,
    linkActiveClass: 'is-active',
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
            };
        }
    },
});


// detect modal
const isRouteModal = (path) => {
    switch (path) {
    case ROUTES.MODAL.AUTH.LOGIN:
    case ROUTES.MODAL.AUTH.FORGOT_PASSWORD:
    case ROUTES.MODAL.AUTH.RESET_PASSWORD:
    case ROUTES.MODAL.AUTH.REGISTER:
    case ROUTES.MODAL.AUTH.LOGOUT:
    case ROUTES.MODAL.AUTH.FINISH_GUEST:
    case ROUTES.MODAL.CREATE_QUIZ:
        return true;
    }
    return false;
};


router.beforeEach((to, from, next) => {
    store.commit(types.ROUTE_CHANGED);

    const isModalRoute = isRouteModal(to.path) || isRouteModal(from.path);
    const hasModalQuery = (!isEmpty(to.query) && !isEmpty(to.query.modal)) || (!isEmpty(from.query) && !isEmpty(from.query.modal));

    if (isModalRoute) {
        next({ path: from.path, query: merge({}, from.query || {}, to.query || {}, { modal: to.path }) });
        return;
    }

    // make sure we have atleat a promise in there
    const promises = [Promise.resolve()];

    // scroll to top if not route modal
    if (!isModalRoute && !hasModalQuery) {
        promises.push(scrollToTop());
    }


    // load page if the meta id is there
    if (!isEmpty(to.meta) && !isEmpty(to.meta.id)) {
        promises.push(store.dispatch(types.LOAD_PAGE, to.meta.id));
    }


    // wait for the tween and the load page before going to the next() route
    Promise.all(promises)
        .then(() => {
            next();
        })
        .catch((e) => {
            next();
        });
});


const hasQueryParams = route => !!Object.keys(route.query).length;

// protect route if needed login + redirect GET params
router.beforeEach((to, from, next) => {
    // check for login needed routes
    if (to.matched.some(record => record.meta.requireLogin) && store.state.user.currentUser === null) {
        // go to login with the query of the old path
        next({ path: ROUTES.LOGIN, query: { redirect: to.fullPath } });
    } else if (!hasQueryParams(to) && hasQueryParams(from) && from.path === ROUTES.LOGIN && to.path === ROUTES.LOGIN) {
        // keep the query if we are inside the login
        const toWithQuery = Object.assign({}, to, { query: from.query });
        next(toWithQuery);
    } else {
        // normal path
        next();
    }
});

// Add body classes
router.afterEach((to, from) => {
    document.body.classList.remove(`p-${from.name}`);
    document.body.classList.add(`p-${to.name}`);
});

// expose router so we can use it anywhere
window.vueRouter = router;

export default router;
