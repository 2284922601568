<template>
    <div class="o-input-check">
        <input
            :id="_uid"
            :type="type"
            :name="name"
            :checked="inputIsChecked"
            @change.prevent="onInputEvent"
        >
        <label :for="_uid">
            <i class="o-input-check__icon | o-icon">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17" class="svg-checkbox">
                    <g class="checkbox">
                        <path d="M13,12v1.5c0,0.8-0.6,1.4-1.4,1.4H3.5c-0.8,0-1.4-0.6-1.4-1.4V5.3C2.1,4.6,2.7,4,3.5,4h5.4l1.3-2.1H3.5C1.5,1.9,0,3.4,0,5.3v8.2C0,15.4,1.5,17,3.5,17h8.1c1.9,0,3.5-1.6,3.5-3.5V8.6L13,12z"></path>
                        <polygon points="9,13.2 4.2,9.6 5.5,7.9 8.4,10.1 14.1,0 16,1"></polygon>
                    </g>
                    <path class="square" d="M11.6,17H3.5C1.6,17,0,15.4,0,13.5V5.3c0-1.9,1.6-3.5,3.5-3.5h8.1c1.9,0,3.5,1.6,3.5,3.5v8.2C15.1,15.4,13.6,17,11.6,17zM3.5,4C2.7,4,2.1,4.6,2.1,5.3v8.2c0,0.7,0.6,1.4,1.4,1.4h8.1c0.7,0,1.4-0.6,1.4-1.4V5.3C13,4.6,12.4,4,11.6,4H3.5z"></path>
                </svg>
            </i>
            <span
                v-if="label"
                class="o-input-check__text"
            >
                {{ label }}
            </span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'InputCheck',
    props: {
        label: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: 'radio',
        },
        checked: {
            default: null,
        },
        type: {
            type: String,
            default: 'checkbox',
        },
    },
    data() {
        return {
            inputIsChecked: false, // local var to better sync the state
        };
    },
    created() {
        if (this.checked !== null) {
            this.inputIsChecked = this.checked;
        }
    },
    watch: {
        checked(newValue) {
            // if has changed
            if (newValue !== null) {
                this.inputIsChecked = newValue;
            }
        },
    },
    methods: {
        onInputEvent(e) {
            // only update the input when we dont control it by the parent
            if (this.checked === null) {
                this.inputIsChecked = e.target.checked;
            }
            this.$emit('change', e);
        },
    },
};
</script>
