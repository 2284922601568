<template>
    <div class="o-marker">
        <template v-if="marker">
            <slot
                :marker="marker"
                :map="map"
            ></slot>
        </template>
    </div>
</template>

<script>
export default {
    name: 'MapMarker',
    props: ['map', 'lat', 'lng'],
    data() {
        return {
            marker: null,
        };
    },
    watch: {
        lat() {
            this.$nextTick(() => {
                if (!this.marker) return;
                this.marker.setPosition({ lat: parseFloat(this.lat), lng: parseFloat(this.lng) });
            });
        },
        lng() {
            this.$nextTick(() => {
                if (!this.marker) return;
                this.marker.setPosition({ lat: parseFloat(this.lat), lng: parseFloat(this.lng) });
            });
        },
        map() {
            this.$nextTick(() => {
                if (!this.map) return;
                if (!this.marker) return;
                this.marker.setMap(this.map);
            });
        },
    },
    mounted() {
        this.marker = new window.google.maps.Marker({
            map: this.map,
            icon: {
                url: '/static/images/marker.png',
                size: new window.google.maps.Size(25, 25),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(12.5, 12.5),
                scaledSize: new window.google.maps.Size(25, 25),
            },
        });


        this.marker.setPosition({ lat: parseFloat(this.lat), lng: parseFloat(this.lng) });
    },
    beforeDestroy() {
        if (this.marker) {
            this.marker.setMap(null);
            this.marker = null;
        }
    },
};
</script>
