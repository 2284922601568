<template>
    <div class="404 w-screen h-screen flex flex-col justify-center items-center">
        <div class="">
            <h1 class="text-center mb-4 text-yellow text-t1">404</h1>

            <Link
                v-if="$store.getters.isLogin"
                :href="ROUTES.HOME"
                iconBefore="arrow-left"
                bg-color="yellow"
                label="Retour à l'accueil"
            ></Link>
            <p v-else>Veuillez vous connecter</p>
        </div>
    </div>
</template>

<script>
import Link from 'templates/objects/Link';

export default {
    name: 'ErrorPage',
    components: { Link },
};
</script>
