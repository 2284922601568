import Vue from 'vue';
import { ROUTES } from 'config/routes';
import { GOOGLE_MAP_SETTINGS } from 'services/constants/map';
import { isEmpty } from 'services/utils';

// DEFINED GLOBAL VARIABLES AND METHODS IN TEMPLATES
Vue.mixin({
    data() {
        return {
		    GOOGLE_MAP_SETTINGS,
            ROUTES,
        };
    },
    methods: {
	    isEmpty(value) {
	        return isEmpty(value);
        },
    },
});
