import { ROUTES } from 'config/routes';
import ErrorPage from 'templates/views/404';


const Page = () => import(/* webpackChunkName: "public" */ 'templates/views/Page');
const Dashboard = () => import(/* webpackChunkName: "admin" */ 'templates/views/Dashboard');
const Admin = () => import(/* webpackChunkName: "admin" */ 'templates/views/Admin');
const Settings = () => import(/* webpackChunkName: "admin" */ 'templates/views/Settings');
const Quiz = () => import(/* webpackChunkName: "admin" */ 'templates/views/Quiz');
const Question = () => import(/* webpackChunkName: "admin" */ 'templates/views/Question');
const Principe = () => import(/* webpackChunkName: "admin" */ 'templates/views/Principe');
const Result = () => import(/* webpackChunkName: "admin" */ 'templates/views/Result');

const dynamicRoutes = [];

// add craft routes, homepage, etc
Object.keys(window.__initialData__.routes).forEach((pageId) => {
    const pageData = window.__initialData__.routes[pageId];
    dynamicRoutes.push({
        name: pageData.slug,
        component: Page,
        path: pageData.path,
        meta: {
            id: pageData.id,
        },
    });
});

const staticRoutes = [
    {
        path: ROUTES.ADMIN.ROOT,
        component: Admin,
        meta: { requireLogin: true, showInitialLoader: true },
        children: [
            {
                name: 'dashboard',
                path: ROUTES.ADMIN._DASHBOARD,
                meta: { requireLogin: true },
                component: Dashboard,

            },
            {
                name: 'settings',
                path: ROUTES.ADMIN._SETTINGS,
                meta: { requireLogin: true },
                component: Settings,
            },
        ],
    },
    {

        path: ROUTES.QUIZ,
        component: Quiz,
        meta: { requireLogin: true, showInitialLoader: true },
        children: [
            {
                name: 'result',
                path: ROUTES._RESULT,
                component: Result,
            },
            {
                name: 'question',
                path: ROUTES._QUESTION,
                component: Question,
            },
            {
                name: 'principe',
                path: ROUTES._PRINCIPE,
                component: Principe,
            },

        ],
    },
    {
        name: 'create-quiz',
        path: ROUTES.MODAL.CREATE_QUIZ,
    },
    {
        name: 'login',
        path: ROUTES.MODAL.AUTH.LOGIN,
    },
    {
        name: 'forgot',
        path: ROUTES.MODAL.AUTH.FORGOT_PASSWORD,
    },
    {
        name: 'reset-password',
        path: ROUTES.MODAL.AUTH.RESET_PASSWORD,
    },
    {
        name: 'logout',
        path: ROUTES.MODAL.AUTH.LOGOUT,
    },
    {
        name: 'register',
        path: ROUTES.MODAL.AUTH.REGISTER,
    },
    {
        name: 'finish-guest',
        path: ROUTES.MODAL.AUTH.FINISH_GUEST,
    },
    {
        name: '*',
        path: '*',
        redirect: ROUTES.ERROR,
    },
    {
        name: '404',
        path: ROUTES.ERROR,
        component: ErrorPage,
    },
];

// combine
const vueRoutes = dynamicRoutes.concat(staticRoutes);

export default vueRoutes;
