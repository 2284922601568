<template>
    <div class="o-loading">
        <slot></slot>
        <transition name="anim-loader">
            <Loader v-if="$store.state.general.loaderName.includes(loaderName)" :size="size"></Loader>
        </transition>
    </div>
</template>

<script>
import Loader from 'templates/objects/Loader';

export default {
    name: 'LoadingContainer',
    components: {
        Loader,
    },
    props: {
        loaderName: {
            type: String,
            required: true,
            default: '',
        },
        size: {
            type: String,
            default: 'normal',
        },
    },
};
</script>
