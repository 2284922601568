<template>
    <LoadingContainer loaderName="deleteQuiz">
        <div class="o-modal-default__content">
            <h4 class="o-modal-default__title | t-t4">Êtes-vous sûr de vouloir <br>supprimer cet emplacement ?</h4>
            <p>Vous ne pourrez plus comparer cet emplacement et il sera retiré de votre liste.</p>
        </div>

        <div
            class="flex justify-between"
        >
            <Link
                @click="goBack"
                label="Annuler"
            ></Link>
            <Btn
                label="Supprimer"
                :simple="true"
                class="c-btn--grey"
                @click="deleteQuiz"
            ></Btn>
        </div>
    </LoadingContainer>
</template>

<script>

import Btn from 'templates/objects/Btn';
import Link from 'templates/objects/Link';
import types from 'store/types';
import LoadingContainer from 'templates/containers/LoadingContainer';

export default {
    name: 'DeleteQuiz',
    components: {
        LoadingContainer,
        Btn,
        Link,
    },
    methods: {
        deleteQuiz() {
            this.$store.dispatch(types.DELETE_QUIZ, this.$route.query.quizId)
                .then(() => {
                    this.goBack();
                });
        },
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>
