import Vue from 'vue';
import types from 'store/types';
import axios from 'axios';
import apiActions from 'config/api-actions';
import DataAPI from 'services/api/data';
import { isEmpty } from 'services/utils';
import merge from 'lodash/merge';

let initDataQuizs = [];
if (window.__initialData__) {
    initDataQuizs = JSON.parse(JSON.stringify(window.__initialData__.quizs || []));
}
const state = {
    temporaryLocation: null,
    email: '',
    emailIsGuest: false,
    emailExist: false,
    quizs: initDataQuizs || [],
    isFetch: false,
    progress: 0,
};

// getters, make function easy to access by vue
// instead
const getters = {
    getQuizById: state => id => state.quizs.find(q => q.id === id),
    getQuizByUid: state => uid => state.quizs.find(q => q.uid === uid),
};

// actions
const actions = {
    [types.SET_EMAIL](store, email) {
        store.commit(types.SET_EMAIL, email);
        return new Promise((resolve, reject) => {
            DataAPI.doesEmailExist(email)
                .then((data) => {
                    store.commit(types.SET_EMAIL_EXIST, data.exist);
                    store.commit(types.SET_EMAIL_GUEST, data.isGuest);
                    resolve(data);
                })
                .catch((err) => {
                    resolve(false);
                });
        });
    },
    [types.SET_QUIZ_CREATION_TEMP_LOCATION](store, location) {
        store.commit(types.SET_QUIZ_CREATION_TEMP_LOCATION, location);
    },
    [types.SET_PROGRESS](store, progress) {
        store.commit(types.SET_PROGRESS, progress);
    },
    [types.SELECT_ANSWER](store, data) {
        store.commit(types.SELECT_ANSWER, data);
    },
    [types.INIT_QUIZ](store, data) {
        return new Promise((resolve, reject) => {
            axios.post(apiActions.quiz.create, data)
                .then((data) => {
                    if (!data.success) {
                        reject(data);
                        return;
                    }
                    resolve(data.quiz);
                    store.commit(types.UPDATE_QUIZ, data.quiz);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [types.UPDATE_QUIZ](store, updatedData) {
        if (!isEmpty(updatedData.updateStateNow) && updatedData.updateStateNow) {
            store.commit(types.UPDATE_MERGE_QUIZ, updatedData);
        }
        return new Promise((resolve, reject) => {
            axios.post(apiActions.quiz.update, updatedData)
                .then((data) => {
                    if (!data.success) {
                        reject(data);
                        return;
                    }

                    resolve(data.quiz);

                    // dont update we could have call server multiple time
                    if (isEmpty(updatedData.updateStateNow)) {
                        store.commit(types.UPDATE_QUIZ, data.quiz);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    [types.DELETE_QUIZ](store, quizId) {
        store.dispatch(types.START_LOADER, 'deleteQuiz');
        return new Promise((resolve, reject) => {
            axios.post(apiActions.quiz.delete, { quizId })
                .then((data) => {
                    if (!data.success) {
                        reject(data);
                        return;
                    }

                    resolve(data);
                    store.commit(types.DELETE_QUIZ, quizId);
                    store.dispatch(types.STOP_LOADER, 'deleteQuiz');
                })
                .catch((err) => {
                    reject(err);
                    store.dispatch(types.STOP_LOADER, 'deleteQuiz');
                });
        });
    },
    [types.FETCH_CURRENT_USER_DATA](store) {
        store.dispatch(types.START_LOADER, 'userData');
        // Must be call when the user is connected because it required the session token to be valid
        const quizsPromise = DataAPI.getQuiz();

        Promise.all([quizsPromise])
            .then((results) => {
                const data = {
                    quizs: results[0],
                };
                store.commit(types.FETCH_CURRENT_USER_DATA, data);
                store.dispatch(types.STOP_LOADER, 'userData');
            });
    },
};

// mutations
const mutations = {
    [types.SET_PROGRESS](state, progress) {
        state.progress = progress;
    },
    [types.SET_EMAIL](state, email) {
        state.email = email;
    },
    [types.SET_EMAIL_EXIST](state, exist) {
        state.emailExist = exist;
    },
    [types.SET_EMAIL_GUEST](state, isGuest) {
        state.emailIsGuest = isGuest;
    },
    [types.SET_QUIZ_CREATION_TEMP_LOCATION](state, location) {
        state.temporaryLocation = location;
    },
    [types.UPDATE_QUIZ](state, quiz) {
        const idx = state.quizs.findIndex(q => q.id === quiz.id);
        if (idx === -1) {
            state.quizs.push(quiz);
        } else {
            Vue.set(state.quizs, idx, quiz);// replace with new quiz
        }
    },
    [types.DELETE_QUIZ](state, quizId) {
        const idx = state.quizs.findIndex(q => q.id === quizId);
        if (idx !== -1) {
            Vue.delete(state.quizs, idx);// replace with new quiz
        }
    },
    [types.UPDATE_MERGE_QUIZ](state, data) {
        const idx = state.quizs.findIndex(q => q.id === data.quizId);
        if (idx === -1) return;

        Vue.set(state.quizs, idx, merge({}, state.quizs[idx], data.data));// replace with new quiz
    },
    [types.SELECT_ANSWER](state, data) {
        const idx = state.quizs.findIndex(q => q.id === data.quizId);
        if (idx !== -1) {
            // grab old answers

            let questionsAnswers = {};// default to empty obj
            if (!isEmpty(state.quizs[idx].questionsAnswers)) {
                questionsAnswers = JSON.parse(JSON.stringify(state.quizs[idx].questionsAnswers));
            }

            // add new answer
            questionsAnswers[data.questionId] = data.answer;

            // use vue set because we change a property and not the entire object
            Vue.set(state.quizs[idx], 'questionsAnswers', questionsAnswers);
        }
    },
    [types.FETCH_CURRENT_USER_DATA](state, data) {
        // only add quiz that we dont have
        data.quizs.forEach((quiz) => {
            if (state.quizs.findIndex(q => q.id === quiz.id) === -1) {
                state.quizs.push(quiz);
            }
        });
        state.isFetch = true;
    },
    [types.LOGOUT_USER](state) {
        state.quizs = [];
        state.progress = 0;
        state.email = '';
        state.emailExist = false;
        state.isFetch = false;
        state.temporaryLocation = null;
    },
};

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
};
