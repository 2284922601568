import Vue from 'vue';

export const EventBus = new Vue();

export const EVENTS = {
    GOOGLE_MAP_IS_INIT: 'GOOGLE_MAP_IS_INIT',
};


// EventBus.$emit(EVENTS.<>, payload)
// EventBus.$on(EVENTS.<>, functionName)
// beforeDestroy => EventBus.$off(EVENTS.<>, functionName)
