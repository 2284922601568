import { buildQuery } from 'services/utils';

const loadScript = (params) => {
    const script = document.createElement('script');

    script.type = 'text/javascript';

    params.callback = 'googleMapsAutoCompleteAPILoad';

    script.src = `https://maps.googleapis.com/maps/api/js?${buildQuery(params)}`;


    document.querySelector('head').appendChild(script);
};

const loadGoogleMap = params => new Promise(((resolve, reject) => {
    window.googleMapsAutoCompleteAPILoad = function () {
        resolve(window.google);
    };

    loadScript(params);

    setTimeout(() => {
        if (!window.google) {
            reject(new Error('Loading took too long'));
        }
    }, 5000);
}));


export function createPopupClass() {
    function Popup(position, nodeContent) {
        this.position = position;

        nodeContent.classList.add('o-map-popup-bubble');

        // This zero-height div is positioned at the bottom of the bubble.
        const bubbleAnchor = document.createElement('div');
        bubbleAnchor.classList.add('o-map-popup-bubble-anchor');
        bubbleAnchor.appendChild(nodeContent);

        // This zero-height div is positioned at the bottom of the tip.
        this.containerDiv = document.createElement('div');
        this.containerDiv.classList.add('o-map-popup-container');
        this.containerDiv.appendChild(bubbleAnchor);

        // Optionally stop clicks, etc., from bubbling up to the map.
        google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
    }

    // ES5 magic to extend google.maps.OverlayView.
    Popup.prototype = Object.create(google.maps.OverlayView.prototype);

    /** Called when the popup is added to the map. */
    Popup.prototype.onAdd = function () {
        this.getPanes().floatPane.appendChild(this.containerDiv);
    };

    Popup.prototype.setContent = function (nodeContent) {
        this.onRemove();
        nodeContent.classList.add('o-map-popup-bubble');

        // This zero-height div is positioned at the bottom of the bubble.
        const bubbleAnchor = document.createElement('div');
        bubbleAnchor.classList.add('o-map-popup-bubble-anchor');
        bubbleAnchor.appendChild(nodeContent);

        // This zero-height div is positioned at the bottom of the tip.
        this.containerDiv = document.createElement('div');
        this.containerDiv.classList.add('o-map-popup-container');
        this.containerDiv.appendChild(bubbleAnchor);

        this.onAdd();
    };

    /** Called when the popup is removed from the map. */
    Popup.prototype.onRemove = function () {
        if (this.containerDiv.parentElement) {
            this.containerDiv.parentElement.removeChild(this.containerDiv);
        }
    };

    /** Called each frame when the popup needs to draw itself. */
    Popup.prototype.draw = function () {
        const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

        // Hide the popup when it is far out of view.
        const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
            ? 'block'
            : 'none';

        if (display === 'block') {
            this.containerDiv.style.left = `${divPosition.x}px`;
            this.containerDiv.style.top = `${divPosition.y}px`;
        }
        if (this.containerDiv.style.display !== display) {
            this.containerDiv.style.display = display;
        }
    };

    return Popup;
}


export default loadGoogleMap;
