<template>
    <div class="update-location-name" @click.stop="">
        <InputText
            class="c-form__el--simplify"
            :name="`${quizId_}-location-name`"
            type="text"
            v-model="locationName"
            label=""
            :editable="true"
            :show-save-btn="nameHasChange"
            v-on:save="changeLocationName"
            :multi-line="true"
        ></InputText>
    </div>
</template>

<script>
import InputText from 'templates/objects/InputText';
import types from 'store/types';
import {isEmpty} from 'services/utils';

export default {
    name: 'update-location-name',
    components: {InputText},
    props: {
        quizId: {
            type: String,
            default: null,
            required: false,
        },
    },
    data() {
        return {
            quizId_: null,
            locationName: '',
        };
    },
    watch: {
        quizId(newId) {
            //update internal value
            if (isEmpty(newId)) {
                if (newId !== this.quizId_) {
                    this.quizId_ = newId
                }
            }
        }
    },
    mounted() {
        if (isEmpty(this.quizId)) {
            if (this.$route.params.quizUid) {
                let quiz = this.$store.getters.getQuizByUid(this.$route.params.quizUid);
                if (quiz) {
                    this.quizId_ = quiz.id;
                }
            }
        } else {
            this.quizId_ = this.quizId;
        }

        this.$nextTick(() => {
            if (this.quiz) {
                this.locationName = this.quiz.location.name;
            }
        });
    },
    computed: {
        nameHasChange() {
            if (!this.quiz) return false;
            if (!this.quiz.location) return false;
            return this.locationName !== this.quiz.location.name;
        },
        quiz() {
            return this.$store.getters.getQuizById(this.quizId_);
        },
    },
    methods: {
        changeLocationName() {
            if (!this.quiz) return;
            if (this.locationName === '') return;
            if (this.locationName === this.quiz.location.name) return;
            this.$store.dispatch(types.UPDATE_QUIZ, {
                quizId: this.quizId_,
                updateStateNow: true,
                data: {
                    location: {
                        name: this.locationName,
                    },
                },
            });
        },
    },
};
</script>


<style lang="scss">
    .update-location-name {
        font-size: 17px;
        font-weight: 600;
        letter-spacing: 0.05em;
        line-height: 20px;

    }
</style>
