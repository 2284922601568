<template>
    <Box
        class="o-modal-default"
        :icon="icon"
        :title="title"
    >
        <Settings key="Settings" v-if="component === ROUTES.MODAL.SUCCESS.SETTINGS"></Settings>
        <BackToHome key="backtohome" v-if="component === ROUTES.MODAL.WARN.BACK_TO_HOME"></BackToHome>
        <DeleteQuiz key="deletequiz" v-if="component === ROUTES.MODAL.WARN.DELETE_QUIZ"></DeleteQuiz>
        <DeleteAccount key="deleteaccount" v-if="component === ROUTES.MODAL.WARN.DELETE_ACCOUNT"></DeleteAccount>
    </Box>
</template>

<script>

import Box from 'templates/components/Box';
import Settings from 'templates/modals/success/Settings';
import BackToHome from 'templates/modals/warn/BackToHome';
import DeleteQuiz from 'templates/modals/warn/DeleteQuiz';

import { ROUTES } from 'config/routes';
import DeleteAccount from 'templates/modals/warn/DeleteAccount';

export default {
    name: 'Default',
    components: {
        DeleteAccount,
        Box,
        Settings,
        BackToHome,
        DeleteQuiz,
    },
    data: () => ({
        title: 'Attention',
        icon: 'warn',
    }),
    computed: {
        component() {
            if (!this.$route.query) return null;
            if (!this.$route.query.modal) return null;
            return this.$route.query.modal;
        },
    },
    created() {
        if (this.component === ROUTES.MODAL.SUCCESS.SETTINGS) {
            this.title = 'Succès';
            this.icon = 'check';
        }
    },
};

</script>

<style lang="scss">

    .o-modal-default {
        padding-top: 2em;

        .c-box__inner {
            max-width: 420px;
            margin-top: 0 !important;
            margin-right: auto;
            margin-left: auto;
            padding-top: 0 !important;
        }

        .c-box__content {
        }

        .o-icon {
            --color-bg: #{$color-red};
        }
    }

    .o-modal-default__content {
        margin-bottom: 2em;
        text-align: center;
    }

    .o-modal-default__title {
        margin-bottom: 1em;
    }

</style>
