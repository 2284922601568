import { isEmpty } from 'services/utils';

export const validateErrorsForm = (data, expected) => {
    const validationErrors = {};

    // Email
    if (expected.includes('email')) {
        if (isEmpty(data.email)) {
            validationErrors.email = 'Le courriel ne peut pas être vide';
        } else if (!data.email.includes('@') || !data.email.includes('.')) {
            // stupid check for @ and . in the email, regex is not valid every email, so a stupid check is enough
            validationErrors.email = 'Le courriel n\'est pas valide';
        }
    }

    // First name
    if (expected.includes('firstName') && isEmpty(data.firstName)) {
        validationErrors.firstName = 'Nous avons besoin de votre prénom';
    }

    // Last name
    if (expected.includes('lastName') && isEmpty(data.lastName)) {
        validationErrors.lastName = 'Nous avons besoin de votre nom';
    }

    // Password
    if (expected.includes('password')) {
        if (isEmpty(data.password)) {
            validationErrors.password = 'Le mot de passe ne peut pas être vide';
        } else if (data.password.length < 7) {
            validationErrors.password = 'Le mot de passe doit contenir plus de 6 caractères';
        }
    }

    // New password
    if (expected.includes('newPassword')) {
        if (isEmpty(data.newPassword)) {
            validationErrors.newPassword = 'Le mot de passe ne peut pas être vide';
        } else if (data.newPassword.length < 7) {
            validationErrors.newPassword = 'Le mot de passe doit être plus long que 6 charactères';
        }
    }

    // Code
    if (expected.includes('code') && isEmpty(data.code)) {
        validationErrors.code = 'Le code ne peut pas être vide';
    }

    return validationErrors;
};


export const getValidationErrorMessage = (name) => {
    // fake validation, just to get error message
    const errors = validateErrorsForm({ [name]: '' }, [name]);
    return errors[name];
};
