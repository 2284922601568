import types from 'store/types';
import DataAPI from 'services/api/data';

const state = {
    isFetch: false,
    questions: [],
    principes: [],
};


// getters, make function easy to access by vue
// instead
const getters = {
    getQuestionByIdx: state => idx => state.questions.find(q => q.idx === idx),
    getQuestionById: state => id => state.questions.find(q => q.id === id),
    getQuestionByUid: state => uid => state.questions.find(q => q.uid === uid),
    getQuestionBySlug: state => slug => state.questions.find(q => q.slug === slug),
    getAnswerByUuid: state => uuid => state.questions.find(q => q.answers.map(a => a.uuid).includes(uuid)),
    getPrincipeById: state => id => state.principes.find(p => p.id === id),
    getPrincipesChildren: state => state.principes.filter(p => p.parentId !== null),
    getPrincipesChildrenForPrincipe: state => parentId => state.principes.filter(p => p.parentId === parentId),
    getPrincipesParent: state => state.principes.filter(p => p.parentId === null),
    getPrincipesParentById: (state, getters) => id => getters.getPrincipesParent.find(p => p.id === id),
    getQuestionsForPrincipe: state => id => state.questions.filter(q => q.principeId === id),

};

// actions
const actions = {
    [types.FETCH_INITIAL_DATA](store) {
        const questionsPromise = DataAPI.getQuestions();
        const principesPromise = DataAPI.getPrincipes();

        return Promise.all([questionsPromise, principesPromise])
            .then((results) => {
                const data = {
                    questions: results[0],
                    principes: results[1],
                };
                store.commit(types.FETCH_INITIAL_DATA, data);
            });
    },

};

// mutations
const mutations = {
    [types.FETCH_INITIAL_DATA](state, data) {
        state.isFetch = true;
        state.questions = data.questions;
        state.principes = data.principes;
    },
};

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
};
