<template>
    <span
        class="o-rounded"
        :class="size ? `o-rounded--${size}` : '' "
    >
        <span class="o-rounded__inner">
            <slot />
        </span>
    </span>
</template>


<script>
export default {
    name: 'Rounded',
    props: {
        size: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss">

    .o-rounded {
        --color-bg: #{$color-light};

        display: inline-block;
        padding: 1em 1.5em;
        background-color: var(--color-bg);
        border-radius: 4em;
        box-shadow: $shadow;

        &--xs {
            padding: .3em .7em;
        }
    }

    .o-rounded__inner {
        display: flex;
        align-items: center;
        font-weight: 700;

        > * {

            &:nth-child(n+2) {
                margin-left: .75rem;
            }
        }
    }

</style>
