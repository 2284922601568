<template>
    <div class="o-map-infowindow">
        <InfoWindow ref="infoWindow" :title="label" :address="address" ></InfoWindow>
    </div>
</template>

<script>
import InfoWindow from 'templates/objects/InfoWindow';

export default {
    name: 'MapInfoWindow',
    components: { InfoWindow },
    props: ['map', 'marker', 'label', 'address'],
    watch: {
        label() {
            this.$nextTick(() => {
                if (!this.infoWindow) return;
                this.infoWindow.setContent(this.$refs.infoWindow.$el);
            });
        },
    },
    mounted() {
        this.infoWindow = new window.google.maps.InfoWindow({
            content: this.$refs.infoWindow.$el,
            disableAutoPan: false, // dont make the map zoom or move to fit info window
        });

        setTimeout(() => {
            if (this.infoWindow) this.infoWindow.open(this.map, this.marker);
        }, 300);
    },
    beforeDestroy() {
        if (this.infoWindow) this.infoWindow.setMap(null);
    },
};
</script>


<style lang="scss" scoped>
    //Fix the glitch before the info window go inside the map
    //because info window should not appear itslef, it always need to be inside a map
    .o-map-infowindow {
        display:none!important;
    }
</style>
