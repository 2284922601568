<template>
    <div class="p-register">
        <LoadingContainer loader-name="register">
            <h1 class="t-t2">Créer un compte</h1>
            <div class="c-form">
                <RegisterForm
                    ref="registerForm"
                    @submit="submitRegister"
                />

                <div class="c-form__btns">
                    <Link
                        label="Vous avez déjà un compte ?"
                        :href="ROUTES.MODAL.AUTH.LOGIN"
                    />
                    <Btn
                        label="Créer mon compte"
                        bgColor="yellow"
                        class="c-form__submit"
                        @click="submitRegister"
                    />
                </div>
            </div>
        </LoadingContainer>
    </div>
</template>
<script>

import types from 'store/types';
import { ROUTES } from 'config/routes';
import LoadingContainer from 'templates/containers/LoadingContainer';
import Btn from 'templates/objects/Btn';
import Link from 'templates/objects/Link';
import RegisterForm from 'templates/modals/auth/forms/RegisterForm';


export default {
    name: 'Register',
    components: {
        RegisterForm, Link, Btn, LoadingContainer,
    },
    created() {
        if (this.$store.state.user.currentUser !== null) {
            this.$store.dispatch(types.LOGOUT_USER, false);
        }
    },
    methods: {
        submitRegister(event) {
            if (event) event.preventDefault();
            this.$refs.registerForm.submitRegisterForm()
                .then(() => {
                    let route = ROUTES.ADMIN.ROOT;// default route

                    // grab route from old redirect if exist
                    if (typeof this.$route.query.redirect !== 'undefined') {
                        route = this.$route.query.redirect;
                    }
                    this.$router.push(route);
                })
                .catch(() => {
                    // error
                });
        },
    },
};
</script>
