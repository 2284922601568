export default {
    graphQL: '/api',
    data: {
        getQuestions: '/actions/module/data/get-questions',
        getPrincipes: '/actions/module/data/get-principes',
    },
    quiz: {
        create: '/actions/module/quiz/create',
        update: '/actions/module/quiz/update',
        getAll: '/actions/module/quiz/get-all',
        delete: '/actions/module/quiz/delete',
        generatePDF: '/actions/module/quiz/generate-pdf',
    },
    user: {
        getCurrentUser: '/actions/module/user/me',
        update: '/actions/module/user/update',
        getUidFromEmail: '/actions/module/user/get-uid-from-email',
        registerGuestUser: '/actions/module/user/register-guest-user',
        finishGuest: '/actions/module/user/finish-guest-user',
        emailExist: '/actions/module/user/email-exist',
        deleteAccount: '/actions/module/user/delete-account',
    },
};
