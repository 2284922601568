<template>
    <form class="register" @submit.prevent="onSubmit" @keypress.enter.prevent="onSubmit" method="post"
          accept-charset="UTF-8">

        <div class="l-grid__row justify-between items-start">
            <div class="w-full md:w-1/2">
                <InputText
                    name="firstName"
                    type="text"
                    v-model="firstName"
                    placeholder="Prénom"
                    label="Prénom"
                    :error="validationErrors['firstName'] ? validationErrors['firstName'] : ''"
                />
            </div>
            <div class="w-full md:w-1/2">
                <InputText
                    name="lastName"
                    type="text"
                    v-model="lastName"
                    placeholder="Nom"
                    label="Nom"
                    :error="validationErrors['lastName'] ? validationErrors['lastName'] : ''"
                />
            </div>
        </div>

        <InputText
            name="email"
            type="email"
            v-model="email"
            placeholder="Adresse courriel"
            label="Adresse courriel"
            :error="validationErrors['email'] ? validationErrors['email'] : ''"
        />

        <InputText
            name="password"
            type="password"
            v-model="password"
            placeholder="Mot de passe"
            label="Mot de passe"
            :error="validationErrors['password'] ? validationErrors['password'] : ''"
        />

        <transition name="anim-text">
            <p class="mt-4 error" v-for="error in $store.state.user.errors">{{error}}</p>
        </transition>

    </form>
</template>
<script>
import { ROUTES } from 'config/routes';
import { isEmpty } from 'services/utils';
import types from 'store/types';
import { validateErrorsForm } from 'services/validation';
import InputText from 'templates/objects/InputText';


export default {
    name: 'RegisterForm',
    components: {
        InputText,
    },
    data() {
        return {
            validationErrors: {},
            email: '',
            firstName: '',
            lastName: '',
            password: '',
        };
    },
    methods: {
        onSubmit() {
            this.$emit('submit');
        },
        submitRegisterForm() {
            return new Promise((resolve, reject) => {
                const data = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    password: this.password,
                    fields: {
                        userSortPreference: 'PRESENT',
                    },
                };
                const validationErrors = validateErrorsForm(data, ['email', 'firstName', 'lastName', 'password']);


                // set validationErrors
                this.validationErrors = validationErrors;

                if (!isEmpty(validationErrors)) {
                    reject();
                    return;// don't make the ajax call
                }

                this.$store.dispatch(types.REGISTER_USER, data)
                    .then(() => {
                        resolve();
                    })
                    .catch((err) => {
                        reject();
                    });
            });
        },
    },
};
</script>
