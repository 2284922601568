<template>
    <div class="p-forgot-password">
        <LoadingContainer loaderName="forgot-password">
            <h1 class="t-t2">Terminer la création de votre compte</h1>
            <h4 class="t-t4">Courriel&nbsp;: {{$store.state.user.currentUser.email}}</h4>
            <div class="c-form">
                <FinishGuestForm
                    ref="finishGuestForm"
                    @submit="submitFinishGuest"
                />
                <div class="c-form__btns justify-between items-center">
                    <Link
                        label="Je ne veux pas de compte"
                        :href="ROUTES.MODAL.AUTH.LOGOUT"
                        size="sm"
                    ></Link>
                    <Btn
                        label="Créer mon compte"
                        bgColor="yellow"
                        class="c-form__submit"
                        @click="submitFinishGuest"
                    />
                </div>
            </div>
        </LoadingContainer>
    </div>
</template>

<script>
import Btn from 'templates/objects/Btn';
import LoadingContainer from 'templates/containers/LoadingContainer';
import FinishGuestForm from 'templates/modals/auth/forms/FinishGuestForm';
import { ROUTES } from 'config/routes';
import Link from 'templates/objects/Link';

export default {
    name: 'FinishGuest',
    components: {
        Link, FinishGuestForm, LoadingContainer, Btn,
    },
    methods: {
        submitFinishGuest(event) {
            if (event) event.preventDefault();
            this.$refs.finishGuestForm.submitFinishGuestForm()
                .then(() => {
                    this.$router.push(ROUTES.ADMIN.ROOT);
                })
                .catch(() => {
                    // error
                });
        },
    },
};

</script>
