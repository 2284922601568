<template>
    <div>
        <h3 v-html="skipQuestions.question"></h3>

        <div
            class="o-question"
        >
            <div class="o-question__col">
                <h4 class="o-question__title | t-s">Réponse</h4>
                <div class="o-question__form">
                    <InputCheck
                        :name="`present-${_uid}`"
                        type="radio"
                        @change="answer = 'Y'"
                        :checked="answer === 'Y'"
                        label="Oui"
                    ></InputCheck>
                    <InputCheck
                        :name="`present-${_uid}`"
                        type="radio"
                        @change="answer = 'N'"
                        :checked="answer === 'N'"
                        label="Non"
                    ></InputCheck>

                </div>
            </div>
        </div>
        <p v-if="this.answer === this.skipQuestions.skipOnYesOrNo">
            Votre réponse va vous faire sauter les questions {{questionsIdxThatWillBeSkip.join('-')}}
        </p>

        <p class="error" v-if="error">{{error}}</p>

        <div class="flex justify-end mt-4" v-if="question.answers.length > 0">
            <Btn
                @click="continueQuiz"
                label="Continuer"
                iconAfter="arrow-right"
            ></Btn>
        </div>


    </div>
</template>

<script>
import InputCheck from 'templates/objects/InputCheck';
import { isEmpty } from 'services/utils';
import { DYNAMIC_ROUTES } from 'config/routes';
import Btn from 'templates/objects/Btn';
import types from 'store/types';

export default {
    name: 'skip-questions',
    components: { Btn, InputCheck },
    data() {
        return {
            answer: '',
            error: '',
            questionsIdxThatWillBeSkip: [],
        };
    },
    computed: {
        quiz() {
            if (!this.$route.params.quizUid) {
                return null;
            }
            return this.$store.getters.getQuizByUid(this.$route.params.quizUid);
        },
        question() {
            if (!this.$route.params.questionSlug) {
                return null;
            }

            return this.$store.getters.getQuestionBySlug(this.$route.params.questionSlug);
        },
        principe() {
            if (!this.question || !this.question.principeId) {
                return null;
            }
            return this.$store.getters.getPrincipeById(this.question.principeId);
        },
        skipQuestions() {
            if (!this.principe) return null;

            return this.principe.skipQuestions;
        },
        questionsForPrincipe() {
            return this.$store.getters.getQuestionsForPrincipe(this.principe.id);
        },
    },
    created() {
        this.questionsIdxThatWillBeSkip = this.questionsForPrincipe.map(q => q.idx + 1);


        const quizSkipAnswers = JSON.parse(JSON.stringify(this.quiz.skipAnswers || {}));

        if (quizSkipAnswers) {
            if (typeof quizSkipAnswers[this.principe.id] !== 'undefined') {
                this.answer = quizSkipAnswers[this.principe.id];
            }
        }
    },
    mounted() {
        TweenLite.set(this.$el, {
            '--color-main': '#bce772',
            '--color-light': '#f6ffe8',
        });
    },
    methods: {
        continueQuiz() {
            this.error = '';
            if (isEmpty(this.answer)) {
                this.error = 'Veuillez répondre';
                return;
            }

            const quizSkipAnswers = JSON.parse(JSON.stringify(this.quiz.skipAnswers || {}));
            const quizQuestionAnswers = JSON.parse(JSON.stringify(this.quiz.questionsAnswers || {}));

            // add answer
            quizSkipAnswers[this.principe.id] = this.answer;

            this.$store.dispatch(types.UPDATE_QUIZ, {
                quizId: this.quiz.id,
                updateStateNow: true,
                data: {
                    skipAnswers: quizSkipAnswers,
                },
            });

            if (this.answer !== this.skipQuestions.skipOnYesOrNo) {
                // Dont skip question
                const questions = this.$store.getters.getQuestionsForPrincipe(this.principe.id);

                if (questions.length === 0) {
                    this.$router.back();
                    return;
                }

                const firstQuestion = questions[0];

                this.$router.push(DYNAMIC_ROUTES.QUESTION(this.quiz.uid, firstQuestion.slug));
                return;
            }

            // set skip question to null so we dont register them and backend knows it answer but its skip
            this.questionsForPrincipe.forEach((question) => {
                if (typeof quizQuestionAnswers[question.id] === 'undefined') {
                    quizQuestionAnswers[question.id] = {};
                }
                question.answers.forEach((answer) => {
                    quizQuestionAnswers[question.id][answer.uuid] = {
                        idx: answer.idx,
                        uuid: answer.uuid,
                        present: '',
                        fiveYears: '',
                        isSkip: true,
                    };
                });
            });

            this.$store.dispatch(types.UPDATE_QUIZ, {
                quizId: this.quiz.id,
                updateStateNow: true,
                data: {
                    questionsAnswers: quizQuestionAnswers,
                },
            });

            // SKIP questions

            const currentPrincipeIdx = this.$store.state.data.principes.findIndex(p => p.id === this.principe.id);
            if (currentPrincipeIdx === -1) {
                this.$router.back();
                return;
            }
            const nextPrincipeIdx = currentPrincipeIdx + 1;

            if (nextPrincipeIdx > this.$store.state.data.principes.length + 1) {
                // it was the last principe
                return;
            }

            const questions = this.$store.getters.getQuestionsForPrincipe(this.$store.state.data.principes[nextPrincipeIdx].id);

            if (questions.length === 0) {
                this.$router.back();
                return;
            }

            const firstQuestion = questions[0];

            this.$router.push(DYNAMIC_ROUTES.QUESTION(this.quiz.uid, firstQuestion.slug));

            // Skip question
        },
    },
};
</script>
