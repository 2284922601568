import axios from "axios";
import merge from "lodash/merge";
import types from "store/types";
import { ROUTES, dynamicRoutes } from "config/routes";
import apiActions from "config/api-actions";
import { isEmpty } from "services/utils";
// Static
const axiosConfig = {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
};

// initial state, with craft backend or empty
const state = {
    currentUser: window.__initialData__.currentUser
        ? JSON.parse(JSON.stringify(window.__initialData__.currentUser))
        : null,
    errors: [],
    validationErrors: {},
};

// getters, make function easy to access by vue
const getters = {
    isLogin: (state) => state.currentUser !== null,
    isGuest: (state) => state.currentUser !== null && state.currentUser.isGuest,
    userFullName: (state) =>
        state.currentUser !== null ? `${state.currentUser.firstName} ${state.currentUser.lastName}` : "",
};

// actions
const actions = {
    [types.GET_CURRENT_USER](store) {
        return axios
            .get(apiActions.user.getCurrentUser)
            .then((data) => {
                store.commit(types.GET_CURRENT_USER, data.user);
                return data.user;
            })
            .catch((err) => err);
    },
    [types.LOGIN_USER](store, loginData) {
        return new Promise((resolve, reject) => {
            if (store.state.currentUser !== null) {
                // logout and recall login with same data
                store.dispatch(types.LOGOUT_USER);
            }
            store.dispatch(types.START_LOADER, "login");

            loginData.action = "users/login";

            axios
                .post("/actions/" + loginData.action, loginData, axiosConfig)
                .then((data) => {
                    if (!data.success) {
                        store.dispatch(types.STOP_LOADER, "login");
                        store.commit(types.USER_ERROR, [data.error]);
                        reject();
                        return null;
                    }
                    // login was a success, continue
                    store.dispatch(types.GET_CURRENT_USER).then(() => {
                        store.dispatch(types.FETCH_CURRENT_USER_DATA).then(() => {
                            store.dispatch(types.STOP_LOADER, "login");
                            resolve();
                        });
                    });
                })
                .catch((err) => {
                    reject();
                    store.dispatch(types.STOP_LOADER, "login");
                });
        });
    },
    [types.REGISTER_USER](store, registerData) {
        if (store.state.currentUser != null) {
            store.dispatch(types.LOGOUT_USER, false);
        }
        store.dispatch(types.START_LOADER, "register");

        registerData.action = "users/save-user";
        return new Promise((resolve, reject) => {
            axios
                .post("/actions/" + registerData.action, registerData, axiosConfig)
                .then((data) => {
                    if (!data.success) {
                        store.dispatch(types.STOP_LOADER, "register");
                        const errors = [];
                        if (typeof data.errors.email !== "undefined") {
                            errors.push(data.errors.email[0]);
                        }
                        if (typeof data.errors.password !== "undefined") {
                            errors.push(data.errors.password[0]);
                        }
                        store.commit(types.USER_ERROR, errors);
                        reject();
                        return null;
                    }
                    // login was a success, redirect to dashboard
                    store.dispatch(types.GET_CURRENT_USER).then(() => {
                        store.dispatch(types.FETCH_CURRENT_USER_DATA).then(() => {
                            store.dispatch(types.STOP_LOADER, "register");
                            resolve();
                        });
                    });
                })
                .catch((err) => {
                    store.dispatch(types.STOP_LOADER, "register");
                    reject();
                });
        });
    },
    [types.REGISTER_GUEST_USER](store, registerData) {
        if (store.state.currentUser != null) {
            store.dispatch(types.LOGOUT_USER, false);
        }

        return axios
            .post(apiActions.user.registerGuestUser, registerData)
            .then((data) => {
                if (!data.success) {
                    return null;
                }
                // login was a success, redirect to dashboard
                store.commit(types.GET_CURRENT_USER, data.user);
            })
            .catch((err) => err);
    },
    [types.FORGOT_PASSWORD](store, forgotData) {
        store.dispatch(types.START_LOADER, "forgot-password");
        forgotData.action = "users/send-password-reset-email";

        return axios
            .post("/actions/" + forgotData.action, forgotData, axiosConfig)
            .then((data) => {
                if (!data.success) {
                    const errors = Object.values(data.errors).reduce((total, arr) => total.concat(arr), []);

                    store.commit(types.USER_ERROR, errors);
                    return null;
                }

                store.dispatch(types.STOP_LOADER, "forgot-password");
            })
            .catch((err) => {
                store.dispatch(types.STOP_LOADER, "forgot-password");
            });
    },
    [types.RESET_PASSWORD](store, resetData) {
        store.dispatch(types.START_LOADER, "set-password");
        resetData.action = "users/set-password";

        return axios
            .post("/actions/" + resetData.action, resetData, axiosConfig)
            .then((data) => {
                if (!data.success) {
                    store.commit(types.USER_ERROR, [data.error.err]);
                    return null;
                }
                // reset was a success, redirect to login
                window.vueRouter.push(ROUTES.LOGIN);
                store.dispatch(types.STOP_LOADER, "set-password");
            })
            .catch((err) => {
                store.dispatch(types.STOP_LOADER, "set-password");
            });
    },
    [types.LOGOUT_USER](store, redirect = true) {
        store.dispatch(types.START_LOADER, "logout");

        return axios
            .get(ROUTES.MODAL.AUTH.LOGOUT)
            .then((data) => {
                store.dispatch(types.START_LOADER, "logout");

                if (!data.success) {
                    store.commit(types.USER_ERROR, [data.error.err]);
                    return null;
                }
                store.commit(types.LOGOUT_USER);
                if (redirect) {
                    window.vueRouter.push(ROUTES.HOME);
                }
            })
            .catch((err) => {
                store.dispatch(types.START_LOADER, "logout");
                return err;
            });
    },
    [types.MODIFY_USER](store, formData) {
        store.dispatch(types.START_LOADER, "profil");

        // commmit right now so ui can change
        store.commit(types.MODIFY_USER, formData);

        axios
            .post(apiActions.user.update, { userData: formData })
            .then((data) => {
                store.dispatch(types.STOP_LOADER, "profil");
                if (data.success) {
                    store.commit(types.MODIFY_USER, data.user);
                } else {
                    store.commit(types.USER_ERROR, [data.error.err]);
                }
            })
            .catch((err) => {
                store.dispatch(types.STOP_LOADER, "profil");
            });
    },
    [types.FINISH_GUEST](store, formData) {
        store.dispatch(types.START_LOADER, "finish-guest");

        // commmit right now so ui can change
        store.commit(types.MODIFY_USER, formData);

        axios
            .post(apiActions.user.finishGuest, { userData: formData })
            .then((data) => {
                store.dispatch(types.STOP_LOADER, "finish-guest");
                if (data.success) {
                    store.commit(types.MODIFY_USER, data.user);
                } else {
                    store.commit(types.USER_ERROR, [data.error.err]);
                }
            })
            .catch((err) => {
                store.dispatch(types.STOP_LOADER, "finish-guest");
            });
    },
    [types.DELETE_ACCOUNT](store, userId) {
        store.dispatch(types.START_LOADER, "delete-account");
        axios
            .post(apiActions.user.deleteAccount, { userId })
            .then((data) => {
                store.dispatch(types.STOP_LOADER, "delete-account");
                window.vueRouter.push(ROUTES.HOME);
            })
            .catch((err) => {
                store.dispatch(types.STOP_LOADER, "delete-account");
            });
    },
};

// mutations
const mutations = {
    [types.GET_CURRENT_USER](state, user) {
        state.currentUser = user;
        state.errors = [];
        state.validationErrors = {};
    },
    [types.LOGOUT_USER](state) {
        state.currentUser = null;
        state.errors = [];
        state.validationErrors = {};
    },
    [types.USER_ERROR](state, err) {
        state.errors = err;
    },
    [types.MODIFY_USER](state, formUserData) {
        state.currentUser = merge({}, state.currentUser, formUserData);
    },
    [types.ROUTE_CHANGED](state) {
        state.errors = [];
    },
};

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
};
