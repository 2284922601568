import axios from 'axios';
import apiActions from 'config/api-actions';

export default {
    getQuestions() {
        return axios.get(apiActions.data.getQuestions)
            .then(data => data.questions || []);
    },
    getPrincipes() {
        return axios.get(apiActions.data.getPrincipes)
            .then(data => data.principes || []);
    },
    getQuiz() {
        return axios.get(apiActions.quiz.getAll)
            .then(data => data.quiz || []);
    },
    doesEmailExist(email) {
        return axios.get(apiActions.user.emailExist, { params: { email } })
            .catch(err => false);
    },
    getQuizResultPdf(quizUid, userId) {
        return axios.post(apiActions.quiz.generatePDF, { quizUid, userId })
            .then(data => data.urls || [])
            .catch(err => []);
    },

};
