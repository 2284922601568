<template>
    <div class="p-login">
        <LoadingContainer loader-name="login">
            <h1 class="t-t2">Se connecter</h1>
            <div class="c-form">
                <LoginForm
                    ref="loginForm"
                    @submit="submitLogin"
                />
                <div class="c-form__btns">
                    <div class="flex flex-wrap items-end">
                        <Link
                            label="Mot de passe oublié ?"
                            :href="ROUTES.MODAL.AUTH.FORGOT_PASSWORD"
                            size="sm"
                            class="mr-4"
                        />
                        <Link
                            label="Pas encore de compte ?"
                            :href="ROUTES.MODAL.AUTH.REGISTER"
                            class="mt-2"
                            size="sm"
                        />
                    </div>
                    <Btn
                        label="Se connecter"
                        bgColor="yellow"
                        class="c-form__submit"
                        @click="submitLogin"
                    />
                </div>
            </div>
        </LoadingContainer>
    </div>
</template>
<script>

import types from 'store/types';
import { ROUTES } from 'config/routes';
import LoginForm from 'templates/modals/auth/forms/LoginForm';
import LoadingContainer from 'templates/containers/LoadingContainer';
import Btn from 'templates/objects/Btn';
import Link from 'templates/objects/Link';


export default {
    name: 'Login',
    components: {
        Link, Btn, LoadingContainer, LoginForm,
    },
    created() {
        if (this.$store.state.user.currentUser !== null) {
            this.$store.dispatch(types.LOGOUT_USER, false);
        }
    },
    methods: {
        submitLogin(event) {
            if (event) event.preventDefault();
            this.$refs.loginForm.submitLoginForm()
                .then(() => {
                    let route = ROUTES.ADMIN.ROOT;// default route

                    // grab route from old redirect if exist
                    if (typeof this.$route.query.redirect !== 'undefined') {
                        route = this.$route.query.redirect;
                    }

                    this.$router.push(route);
                })
                .catch(() => {
                    // error
                });
        },
    },
};
</script>
