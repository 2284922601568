<template>
    <transition appear name="anim-modal">
        <div
            :class="name ? `c-modal--${name}` : ''"
            ref="modal"
            class="c-modal"
            role="dialog"
        >
            <span class="c-modal__bg"></span>
            <div
                class="c-modal__inner"
                @click.stop=""
            >
                <button
                    type="button"
                    v-if="onClose && canClose"
                    class="c-modal__close"
                    aria-label="Fermer"
                    @click.prevent="_onClose"
                >
                    <Icon icon="cross" size="md"/>
                </button>
                <div class="c-modal__content">
                    <slot/>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

import Icon from 'templates/objects/Icon';

export default {
    name: 'Modal',
    components: {
        Icon,
    },
    props: {
        onClose: Function,
        name: {
            type: String,
            default: '',
        },
        canClose: {
            type: Boolean,
            default: true,
        },
        options: {
            type: Object,
            default: null,
        },
    },
    created() {
        this._defaultOptions = {
            showBodyBehind: true,
        };

        this.bodyClass = [];

        this._options = Object.assign({}, this._defaultOptions, this.options);


        // Add body class
        this.bodyClass.push('has-modal-open');

        if (!this._options.showBodyBehind) {
            this.bodyClass.push('hide-body-behind');
        }

        this.bodyClass.forEach((classname) => {
            document.body.classList.add(classname);
        });
    },
    mounted() {
        // Handle key press
        window.document.addEventListener('keyup', this.onKeyPress);

        // Add click handler
        this.$refs.modal.addEventListener('mousedown touchdown', this.onBodyClick);
    },
    beforeDestroy() {
        // Remove body class
        this.bodyClass.forEach((classname) => {
            document.body.classList.remove(classname);
        });


        // Remove key press and click handler
        window.document.removeEventListener('keyup', this.onKeyPress);
        this.$refs.modal.removeEventListener('mousedown touchdown', this.onBodyClick);
    },
    methods: {
        onKeyPress(e) {
            // If escape key is pressed
            if (e.keyCode === 27) {
                this._onClose();
            }
        },
        onBodyClick(e) {
            this._onClose();
        },
        _onClose() {
            if (typeof this.onClose === 'function' && this.canClose) {
                this.onClose();
            }
        },
    },
};
</script>

<style lang="scss">

    .c-modal {
        z-index: -1;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;

        display: flex;
        justify-content: center;
        padding: 2em 1em;

        overflow-y: auto;
        pointer-events: none;
        transition: z-index 0s .8s;

        // Modifiers
        &--default {
            overflow: visible;

            .c-modal__inner {
                max-width: 480px;
            }

            .c-modal__content {
                padding: 0;
            }
        }

        &--createquiz {

            .c-modal__inner {
                max-width: 1050px;
            }
        }

        .has-modal-open & {
            z-index: 900;
            pointer-events: initial;
            transition: opacity .8s, z-index 0s 0s;
        }
    }

    .c-modal__bg {
        position: fixed;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        // opacity: 0;
        // transition: opacity .3s $in-sine;
    }

    .c-modal__inner {
        width: 100%;
        max-width: 600px;
        margin-top: auto;
        margin-bottom: auto;
        background-color: $color-light;
        border-radius: $radius;

        // opacity: 0;
        // transform: translate(0, 4em) rotateX(90deg);
        transform-origin: 50% 100%;
        // transition: opacity .3s $in-sine, transform .3s $in-expo;
    }

    .c-modal__close {
        z-index: 9;
        position: absolute;
        top: 0;
        right: 0;
        width: 2.75em;
        height: 2.75em;
        color: $color-grey;
        border-radius: inherit;
        cursor: pointer;
        transition: color .2s $out-sine;

        &:hover {
            color: $color-dark;
        }
    }

    .c-modal__content {
        padding: 1.5em;

        @screen md {
            padding: 3em;
        }
    }


    /*===================================
    =            Transitions            =
    ===================================*/

    // Enter-start transform for inner modal
    .anim-modal-enter {

        .c-modal__inner {
            transform: translate(0, 2em) rotateX(20deg);
        }

    }

    // Enter-start and leave-end state
    .anim-modal-enter,
    .anim-modal-leave-to {

        .c-modal__bg {
            opacity: 0;
        }

        .c-modal__inner {
            opacity: 0;
        }
    }

    // Enter-end and leave-start state
    .anim-modal-leave,
    .anim-modal-enter-to {
        opacity: 1;

        .c-modal__bg {
            opacity: 1;
        }

        .c-modal__inner {
            opacity: 1;
            transform: translate(0) rotate(0);
        }
    }

    // Transition on enter
    .anim-modal-enter-active {

        .c-modal__bg {
            transition: opacity .4s $out-sine;
        }

        .c-modal__inner {
            transition: opacity .4s $out-sine, transform .4s $out-expo;
        }
    }


    // Transition on leave
    .anim-modal-leave-active {

        .c-modal__bg {
            transition: opacity .2s $out-sine;
        }

        .c-modal__inner {
            transition: opacity .15s $out-sine;
        }
    }


</style>
