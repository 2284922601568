export const QUEBEC_CITY_CENTER = { lat: 46.8272874, lng: -71.2768882 };

export const SHOW_PLACES = {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
        {
            visibility: 'on',
        },
    ],
};

export const HIDE_PLACES = {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
        {
            visibility: 'off',
        },
    ],
};

export const SHOW_ROADS = {

    featureType: 'road',
    elementType: 'labels',
    stylers: [
        {
            visibility: 'on',
        },
    ],
};

export const HIDE_ROADS = {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
        {
            visibility: 'off',
        },
    ],
};


export const GOOGLE_MAP_SETTINGS = {
    gestureHandling: 'cooperative',
    center: QUEBEC_CITY_CENTER,
    zoom: 13,
    disableDefaultUI: true,
    zoomControl: false,
    clickableIcons: false,
    fullscreenControl: false,
    scrollwheel: false,
    tilt: 0,
    disableDoubleClickZoom: false,
    styles: [
        {
            featureType: 'administrative.land_parcel',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative.neighborhood',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
    ],
};
