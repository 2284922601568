<template>
    <div class="create-quiz">
        <nav class="l-nav mb-4">
            <ul class="l-nav__list  justify-start">
                <li class="l-nav__item">
                    <a
                        @click.prevent="goToState(STATES.LOCATION)"
                        :class="{
                        'is-active': currentState === STATES.LOCATION
                        }"
                        class="l-nav__link">
                        1. Localisation
                        <span></span>
                    </a>
                </li>
                <li class="l-nav__item ml-6">
                    <a
                        @click.prevent="goToState(STATES.CONFIRMATION)"
                        :class="{
                        'is-active': currentState === STATES.CONFIRMATION,
                        'disabled': currentState === STATES.LOCATION
                        }"
                        class="l-nav__link text-tbody">
                        2. Confirmation
                        <span></span>
                    </a>
                </li>
            </ul>
        </nav>
        <!-- Animation will break input autocomplete ! -->
        <div
            :name="STATES.LOCATION"
            v-show="currentState === STATES.LOCATION"
            :key="STATES.LOCATION"
        >
            <div class="map">
                <Map
                    ref="map"
                    :center="center"
                    :options="mapOptions"
                    :showDisplay="false"
                    :showZoom="true"
                >
                    <template slot-scope="{ map }">
                        <MapMarker
                            ref="marker"
                            :map="map"
                            :lat="lat"
                            :lng="lng"
                        >
                        </MapMarker>
                    </template>
                </Map>
            </div>

            <div class="flex flex-wrap justify-between items-end">
                <div class="w-full sm:w-1/2">
                    <InputText
                        label="Adresse"
                        ref="autocompleteInput"
                        name="address"
                        v-model="address"
                        @input="addressInputChange"
                        placeholder="Indiquez un lieu"
                        :error="errors['address'] ? errors['address'] : '' "
                    />
                    <InputText
                        label="Nom de l'emplacement (facultatif)"
                        name="name"
                        v-model="locationName"
                        placeholder="Nom"
                        class="pt-0"
                    />
                </div>
                <div class="w-full sm:w-5/12">
                    <Box
                        type="s"
                        :shadow="true"
                        class="create-quiz__info"
                    >
                        <div class="flex flex-row items-start">
                            <Icon
                                icon="info"
                                class="mt-1"
                            />
                            <p class="ml-4">Entrez l’adresse ou déplacez le curseur pour localiser l’emplacement à
                                évaluer.</p>
                        </div>
                    </Box>

                    <div class="flex flex-row justify-end w-full">
                        <Btn
                            label="Confirmation"
                            bg-color="white"
                            icon-after="arrow-right"
                            @click="goToConfirmation"
                        ></Btn>
                    </div>
                </div>
            </div>
        </div>

        <!-- Animation will break input autocomplete ! -->
        <div
            :name="STATES.CONFIRMATION"
            v-show="currentState === STATES.CONFIRMATION"
            :key="STATES.CONFIRMATION"
        >

            <LoadingContainer loader-name="create-quiz">
                <div class="l-grid__row items-stretch">

                    <div class="w-full md:w-1/2">
                        <div class="mt-6">
                            <Box
                                title="Compte"
                                size="sm"
                                class="c-box--green-light"
                            >
                                <div class="l-account py-6 px-4">
                                    <div v-if="$store.getters.isLogin">
                                        <div v-if="$store.getters.isGuest">
                                            <p>Vous êtes connecté en tant qu'invité. Veuillez finir votre
                                                inscription.</p>
                                            <div class="mt-6 l-grid__row justify-center items-center">
                                                <Link
                                                    type="within"
                                                    :href="ROUTES.ADMIN.ROOT"
                                                    label="Finir votre inscription"
                                                    iconAfter="arrow-right"
                                                ></Link>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <p>Vous êtes connecté en tant que : {{$store.getters.userFullName}}</p>
                                        </div>
                                    </div>
                                    <div v-else>

                                        <p class="font-bold" v-if="$store.state.quiz.emailIsGuest">
                                            Il semblerait que vous ayez déjà évaluer des emplacements à l'aide de ce
                                            courriel. Voulez-vous vous créer un compte ? Vous pourrez alors accéder à
                                            vos évaluations ultérieures et les comparer facilement à l'aide de notre
                                            tableau de bord.
                                        </p>
                                        <p v-else-if="$store.state.quiz.emailExist">
                                            Un compte utilisant le courriel {{$store.state.quiz.email}} existe déjà.
                                            Veuillez d’abord vous connecter pour débuter votre évaluation.
                                        </p>
                                        <p v-else>
                                            Il est fortement recommandé de se créer un compte pour conserver les
                                            évaluations incomplètes et pouvoir les comparer.</p>
                                        <div class="mt-6 l-grid__row justify-center items-center">
                                            <Btn
                                                v-if="!$store.state.quiz.emailExist"
                                                class="mx-2"
                                                @click="goToConnectionLink(ROUTES.MODAL.AUTH.REGISTER)"
                                                label="Inscription"
                                                :simple="true"
                                            >
                                            </Btn>
                                            <Link
                                                v-if="$store.state.quiz.emailIsGuest"
                                                iconAfter="arrow-right"
                                                :href="ROUTES.MODAL.AUTH.RESET_PASSWORD"
                                                label="Terminer mon compte"
                                            >
                                            </Link>
                                            <Btn
                                                v-else
                                                @click="goToConnectionLink(ROUTES.MODAL.AUTH.LOGIN)"
                                                label="Connexion"
                                                class="mx-2"
                                                iconBefore="user"
                                            ></Btn>
                                        </div>
                                    </div>

                                </div>
                            </Box>
                        </div>
                        <div class="mt-12">
                            <Box
                                title="Confidentialité"
                                icon="warn"
                                size="sm"
                                class="c-box--blue-light"
                            >

                                <div class="l-security py-6 px-4">
                                    <p>
                                        Vivre en Ville s'engage à ne jamais divulguer vos informations à des tiers, ceci
                                        comprend également les adresses de vos emplacements évalués ainsi que les
                                        indices d’écoresponsabilité y étant associés.<br>
                                        <router-link tag="a" class="underline" to="/politique-de-confidentialite">
                                            Politique de confidentialité
                                        </router-link>
                                        <br>
                                        <router-link tag="a" class="underline" to="/conditions-d-utilisation">Conditions
                                            d'utilisation
                                        </router-link>
                                    </p>
                                </div>
                            </Box>
                        </div>
                    </div>
                    <div class="w-full md:w-1/2 mt-8 md:mt-0">
                        <div class="flex flex-col justify-between h-full">
                            <div class="small-map">
                                <Map
                                    :options="smallMapOptions"
                                    :showDisplay="false"
                                    :showZoom="false"
                                    :center="center"
                                >
                                    <template slot-scope="{ map }">
                                        <MapMarker
                                            :map="map"
                                            :lat="lat"
                                            :lng="lng"
                                        ></MapMarker>
                                        <MapPopup
                                             :pan="true"
                                            :lat="lat"
                                            :lng="lng"
                                            :map="map"
                                            :label="locationName"
                                            :address="address"
                                        ></MapPopup>
                                    </template>
                                </Map>
                            </div>
                            <div>
                                <InputText
                                    label="Adresse courriel"
                                    v-if="showEmailForm"
                                    name="email"
                                    type="email"
                                    v-model="email"
                                    placeholder="Adresse courriel"
                                    :error="errors['email'] ? errors['email'] : ''"
                                />
                                <div class="bg-greyLight rounded p-6 mt-8">
                                    <!--  <InputCheck
                                          name="confirmExactAddress"
                                          label="Je confirme que l’adresse est exacte ou me semble le plus juste possible."
                                          @change="confirmExactAddress = $event.target.checked"
                                      /> -->
                                    <InputCheck
                                        name="confirmCondition"
                                        label="J’accepte les conditions générales d’utilisation et politique de confidentialité "
                                        @change="confirmCondition = $event.target.checked"
                                    />
                                    <transition name="anim-text">
                                        <p
                                            v-if="errors['confirmation']"
                                            class="error"
                                        >
                                            {{ errors.confirmation }}
                                        </p>
                                    </transition>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-row justify-between mt-4">
                    <Btn
                        label="Retour a l'étape précédente"
                        icon-before="arrow-left"
                        @click="goToState(STATES.LOCATION)"
                    ></Btn>

                    <Btn
                        :disabled="!canStartQuiz"
                        label="Débuter une évaluation"
                        bg-color="yellow"
                        icon-after="arrow-right"
                        @click="startQuiz"
                    ></Btn>
                </div>
            </LoadingContainer>
        </div>

    </div>
</template>

<script>
import Map from 'templates/objects/Map';
import InputText from 'templates/objects/InputText';
import Btn from 'templates/objects/Btn';
import Rounded from 'templates/objects/Rounded';
import Icon from 'templates/objects/Icon';
import Link from 'templates/objects/Link';
import InputCheck from 'templates/objects/InputCheck';
import InfoWindow from 'templates/objects/InfoWindow';
import Box from 'templates/components/Box';
import LoadingContainer from 'templates/containers/LoadingContainer';

import types from 'store/types';
import { DYNAMIC_ROUTES } from 'config/routes';

import { isEmpty } from 'services/utils';
import { GOOGLE_MAP_SETTINGS, QUEBEC_CITY_CENTER } from 'services/constants/map';

import Vue from 'vue';
import merge from 'node_modules/lodash/merge';
import MapMarker from 'templates/objects/MapMarker';
import MapInfoWindow from 'templates/objects/MapInfoWindow';
import MapPopup from 'templates/objects/MapPopup';
import { EventBus, EVENTS } from '@/event-bus';

const STATES = {
    LOCATION: 'LOCATION',
    CONFIRMATION: 'CONFIRMATION',
};
export default {
    name: 'CreateQuiz',
    components: {
        MapPopup,
        Link,
        MapInfoWindow,
        MapMarker,
        LoadingContainer,
        Box,
        InputCheck,
        Icon,
        Btn,
        InputText,
        Map,
    },
    data() {
        return {
            STATES,
            currentState: STATES.LOCATION,
            errors: {},
            email: '',
            showEmailForm: false,
            // Data location
            address: '',
            addressHasBeenAutoComplete: false,
            locationName: '',
            lat: QUEBEC_CITY_CENTER.lat,
            lng: QUEBEC_CITY_CENTER.lng,
            confirmExactAddress: true,
            confirmCondition: false,
            mapOptions: {
                zoom: 15,
                draggable: true,
                disableDefaultUI: true,
                zoomControl: false,
                clickableIcons: false,
            },
            smallMapOptions: {
                zoom: 13,
                draggable: false,
                disableDefaultUI: true,
                zoomControl: false,
                clickableIcons: false,
            },
        };
    },
    created() {
        this.goToState = this.goToState.bind(this);
        this.googleIsInit = this.googleIsInit.bind(this);

        this.autocomplete = null;
        this.geocoder = null;
        this.isLoadingAutoComplete = false;


        // set email for new
        if (this.$store.getters.isLogin) {
            this.$store.dispatch(types.SET_EMAIL, this.$store.state.user.currentUser.email);
            this.email = this.$store.state.quiz.email;
        }

        if (isEmpty(this.$store.state.quiz.email)) {
            this.showEmailForm = true;
        } else if (isEmpty(this.email)) {
            this.email = this.$store.state.quiz.email;
        }

        // init with temporary data if its there
        const { temporaryLocation } = this.$store.state.quiz;
        if (temporaryLocation !== null) {
            this.locationName = temporaryLocation.locationName;
            this.address = temporaryLocation.address;
            this.addressHasBeenAutoComplete = true;
            this.lat = temporaryLocation.lat;
            this.lng = temporaryLocation.lng;


            // if redirected from register, we will have a query currentState
            // but we need a temporary location also
            if (!isEmpty(this.$route.query.currentState)) {
                // make sure its a valid state
                if (Object.values(STATES).includes(this.$route.query.currentState)) {
                    this.currentState = this.$route.query.currentState;
                }
            }
        }


        if (window.navigator) {
            window.navigator.geolocation.getCurrentPosition((position) => {
                this.lat = position.coords.latitude;
                this.lng = position.coords.longitude;
                this.updateAutoCompleteFromLatLng(position.coords.latitude, position.coords.longitude);
            }, (err) => {
                console.log(err);
            }, { timeout: 10000 });
        }
    },
    mounted() {
        if (!window.google) {
            EventBus.$on(EVENTS.GOOGLE_MAP_IS_INIT, this.googleIsInit);
        } else {
            this.googleIsInit();
        }
    },
    beforeDestroy() {
        EventBus.$off(EVENTS.GOOGLE_MAP_IS_INIT, this.googleIsInit);


        // reinit the email values
        this.$store.commit(types.SET_EMAIL, '');
        this.$store.commit(types.SET_EMAIL_GUEST, false);
        this.$store.commit(types.SET_EMAIL_EXIST, false);
    },
    computed: {
        center() {
            return { lat: this.lat, lng: this.lng };
        },
        smallMapCenter() {
            // make a offset to leave room for the info window
            const lattitudeOffsetToLeaveSpaceForInfoWindow = 0;// .080;
            return { lat: this.lat + lattitudeOffsetToLeaveSpaceForInfoWindow, lng: this.lng };
        },
        canStartQuiz() {
            if (this.$store.getters.isLogin) {
                return this.confirmExactAddress && this.confirmCondition;
            }

            return !this.$store.state.quiz.emailExist && this.confirmExactAddress && this.confirmCondition && !isEmpty(this.email);
        },
    },
    watch: {
        currentState() {
            TweenLite.to(this.$parent.$el, 0.4, {
                scrollTo: {
                    y: 0,
                },
                ease: Expo.easeInOut,
            });
        },
    },
    methods: {

        updateAutoCompleteFromLatLng(lat, lng) {
            this.isLoadingAutoComplete = true;
            this.geocoder.geocode({ location: { lat, lng } }, (results, status) => {
                this.isLoadingAutoComplete = false;

                if (status === 'OK') {
                    if (results.length > 0) {
                        this.address = results[0].formatted_address;
                        this.addressHasBeenAutoComplete = true;
                    }
                } else {
                    this.addressHasBeenAutoComplete = false;
                }
            });
        },
        googleIsInit() {
            this.geocoder = new window.google.maps.Geocoder();


            this.autocomplete = new window.google.maps.places.Autocomplete(this.$refs.autocompleteInput.$refs.input);

            // Bind the map's bounds (viewport) property to the autocomplete object,
            // so that the autocomplete requests use the current map bounds for the
            // bounds option in the request.
            this.autocomplete.bindTo('bounds', this.$refs.map.map);

            // Set the data fields to return when the user selects a place.
            this.autocomplete.setFields(
                ['address_components', 'geometry', 'icon', 'name'],
            );

            this.autocomplete.setComponentRestrictions({ country: ['ca'] });


            this.$refs.map.map.addListener('click', (event) => {
                const lat = event.latLng.lat();
                const lng = event.latLng.lng();

                this.lat = lat;
                this.lng = lng;
                this.updateAutoCompleteFromLatLng(lat, lng);
            });


            this.$refs.map.map.addListener('dragend', () => {
                const center = this.$refs.map.map.getCenter();

                this.lat = center.lat();
                this.lng = center.lng();

                this.updateAutoCompleteFromLatLng(center.lat(), center.lng());
            });

            this.autocomplete.addListener('place_changed', (e) => {
                const place = this.autocomplete.getPlace();
                if (!place.geometry) {
                    return;
                }


                this.address = this.$refs.autocompleteInput.$refs.input.value;
                this.addressHasBeenAutoComplete = true;
                Vue.set(this.mapOptions, 'zoom', 17);// Why 17? Because it looks good.

                // If the place has a geometry, then present it on a map.
                if (place.geometry.viewport) {
                    this.$refs.map.map.fitBounds(place.geometry.viewport);
                }

                if (place.geometry.location) {
                    this.lat = place.geometry.location.lat();
                    this.lng = place.geometry.location.lng();
                }
            });


            // set marker
            const { temporaryLocation } = this.$store.state.quiz;
            if (temporaryLocation !== null) {
                this.updateAutoCompleteFromLatLng(this.lat, this.lng);
            }
        },
        goToState(state) {
            this.errors = {};
            if (state !== this.currentState) {
                this.currentState = state;
            }
        },
        addressInputChange(e) {
            // if we change the input manually, we reset the address has been autocomplete
            this.addressHasBeenAutoComplete = false;
        },
        goToConnectionLink(route) {
            // make sure that we go back to create quiz modal
            const urlToRedirect = `${this.$route.fullPath}&currentState=${encodeURI(this.currentState)}`;
            this.$router.push({ path: route, query: { redirect: urlToRedirect } });
        },
        goToConfirmation() {
            this.errors = {};


            if (isEmpty(this.address)) {
                this.errors = {
                    address: 'Veuillez entrer votre adresse',
                };
                return;
            }
            if (!this.addressHasBeenAutoComplete) {
                this.errors = {
                    address: 'Veuillez selectionner votre adresse avec Google',
                };
                return;
            }

            // must wait
            if (this.isLoadingAutoComplete) {
                return;
            }

            const location = {
                address: this.address,
                locationName: this.locationName,
                lat: this.lat,
                lng: this.lng,
            };

            this.$store.dispatch(types.SET_QUIZ_CREATION_TEMP_LOCATION, location);


            this.goToState(STATES.CONFIRMATION);
        },
        startQuiz() {
            this.errors = {};

            if (!this.confirmExactAddress && !this.confirmCondition) {
                this.errors.confirmation = 'Veuillez confirmer l\'adresse et les termes';
                return;
            }
            if (!this.confirmExactAddress) {
                this.errors.confirmation = 'Veuillez confirmer que l\'adresse est exacte';
                return;
            }
            if (!this.confirmCondition) {
                this.errors.confirmation = 'Veuillez accepter les termes d\'utilisation';
                return;
            }

            // will be init with user email if connected
            if (isEmpty(this.email)) {
                this.errors.email = 'Veuillez saisir votre adresse courriel pour débuter l’évaluation';
                return;
            }

            // if we didnt have a email, set it from our input here
            if (!this.$store.getters.isLogin) {
                if (isEmpty(this.$store.state.quiz.email)) {
                    this.$store.dispatch(types.START_LOADER, 'create-quiz');
                    this.$store.dispatch(types.SET_EMAIL, this.email)
                        .then((data) => {
                            this.$store.dispatch(types.STOP_LOADER, 'create-quiz');
                            // make sure it dont exist
                            if (!data.exist) {
                                this.registerAndInitQuiz();
                            }
                        });
                    return;// stop there
                }
            }

            this.registerAndInitQuiz();
        },
        registerAndInitQuiz() {
            const newQuiz = {
                email: this.$store.state.quiz.email,
                location: {
                    address: this.address,
                    locationName: this.locationName,
                    lat: this.lat,
                    lng: this.lng,
                },
            };

            const promises = [];

            // Register guest user
            if (!this.$store.getters.isLogin) {
                promises.push(this.$store.dispatch(types.REGISTER_GUEST_USER, { email: this.$store.state.quiz.email }));
            }

            this.$store.dispatch(types.START_LOADER, 'create-quiz');

            Promise.all(promises)// wait for new guest user or nothing if the user is already connected
                .then(() => {
                    this.$store.dispatch(types.SET_QUIZ_CREATION_TEMP_LOCATION, null);
                    this.$store.dispatch(types.INIT_QUIZ, newQuiz)
                        .then((quiz) => {
                            if (this.$store.state.data.questions.length > 0) {
                                const firstQuestion = this.$store.state.data.questions[0];
                                this.$router.push(DYNAMIC_ROUTES.QUESTION(quiz.uid, firstQuestion.slug));
                            }
                            this.$store.dispatch(types.STOP_LOADER, 'create-quiz');
                        });
                })
                .catch((err) => {
                    this.$store.dispatch(types.STOP_LOADER, 'create-quiz');
                });
        },
    },

};
</script>

<style lang="scss">

    .map {
        height: 400px;

        .o-map {
            padding: 0;
            height: 100%;
        }
    }

    // .o-map {
    // height: 50vh;
    // min-height: 200px;
    // padding-top: 0;
    // }

    .small-map {
        height: 300px;
        pointer-events: none;

        .o-map {
            padding: 0;
            height: 100%;
        }

        * {
            pointer-events: none;
        }
    }

    .l-nav__link {
        @include hover-link($color-blue);

        cursor: pointer;

        &.disabled {
            pointer-events: none;
            color: $color-grey;

            span {
                pointer-events: none;
            }
        }

        &.availble {

        }
    }

    .create-quiz {
        --color-main: #{$color-blue};
    }

    .create-quiz__info {
        max-width: 400px;
        margin-bottom: 1em;

        @screen sm {
            width: calc(100% - 1em);
            margin-top: -2em;
        }
    }

</style>
