import Vue from 'vue';
import Vuex from 'vuex';
import pages from './modules/pages';
import user from './modules/user';
import general from './modules/general';
import data from './modules/data';
import quiz from './modules/quiz';

Vue.use(Vuex);

// Store is where all the actions, and data are stored (save)
const store = new Vuex.Store({
    modules: {
        pages,
        user,
        general,
        data,
        quiz,
    },
    strict: process.env.NODE_ENV !== 'production',
});

window.vueStore = store;


export default store;
