import Vue from 'vue';
import gsap from 'vendors/gsap';// DONT DELETE
import router from 'vue-config/router';
import VueAnalytics from 'vue-analytics';
import checkView from 'vue-check-view';
import store from 'store/index';
import 'config/mixins';
import 'config/filters';
import axios from 'axios';
import types from 'store/types';
import loadGoogleMap from 'services/google-map';
import vueRoutes from 'config/vue-routes';
import { isEmpty } from 'services/utils';
import { EventBus, EVENTS } from '@/event-bus';
import App from './App';

// Reveal plugin
Vue.use(checkView);


// Google analytics
const isProd = process.env.NODE_ENV === 'production';
Vue.use(VueAnalytics, {
    id: 'UA-59718633-23',
    router,
    debug: {
        enabled: !isProd,
        sendHitTask: isProd,
    },
});


// Set vue vars
Vue.config.productionTip = false;
Vue.config.devtools = !isProd;
Vue.config.performance = !isProd;

// Always return data instead of response
axios.interceptors.response.use(response => response.data);


// APP
new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    created() {
        const promises = [];
        // Fetch data
        promises.push(this.$store.dispatch(types.FETCH_INITIAL_DATA));

        if (this.$store.getters.isLogin) {
            promises.push(this.$store.dispatch(types.FETCH_CURRENT_USER_DATA));
        }

        // wait for all data to load before stoping initial loader
        Promise.all(promises)
            .then(() => {
                this.$store.commit(types.STOP_INITIAL_LOADER);
            })
            .catch(() => {
                this.$store.commit(types.STOP_INITIAL_LOADER);
            });


        // Load google map
        loadGoogleMap({
            region: 'CA',
            language: 'fr',
            libraries: 'places',
            key: window.googleApiKey,
        })
            .then((google) => {
                EventBus.$emit(EVENTS.GOOGLE_MAP_IS_INIT, google);
            });

        // Load page from routes
        const routesIds = vueRoutes.reduce((ids, route) => {
            if (!isEmpty(route.meta) && !isEmpty(route.meta.id) && route.name !== 'homepage') {
                ids.push(route.meta.id);
            }

            return ids;
        }, []);

        this.$store.dispatch(types.PRELOAD_PAGES, routesIds);
    },
    mounted() {
        // Uncomment if used
        // document.body.classList.add('ready');
        // document.body.classList.add('js');
        // document.body.classList.remove('no-js');
    },
});


window.Vue = Vue;// expose
