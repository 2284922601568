<template>
    <div class="o-map">
        <ToggleSwitch
            v-if="showDisplay"
            :options="OPTIONS"
            name="googlemap-display"
            class="o-map__display"
            :defaultValue="mapDisplayView"
            @toggleValue="updateDisplay"
        />

        <div v-if="showZoom" class="o-map__zoom">
            <Btn @click="zoomPlus">
                +
            </Btn>
            <Btn @click="zoomMinus">
                -
            </Btn>
        </div>
        <div v-if="showRecenterButton" class="o-map__recenter">
            <Btn @click="recenter">
                <Icon icon="marker"></Icon>
            </Btn>
        </div>

        <div class="o-map__inner">
            <div ref="map" class="o-map__googlemap"></div>
            <template v-if="map">
                <slot :map="map"/>
            </template>
        </div>
    </div>
</template>

<script>
import ToggleSwitch from 'templates/objects/ToggleSwitch';
import Btn from 'templates/objects/Btn';
import Icon from 'templates/objects/Icon';
import {isEmpty} from 'services/utils';
import merge from 'node_modules/lodash/merge';
import debounce from 'lodash/debounce';
import {
    GOOGLE_MAP_SETTINGS, SHOW_PLACES, HIDE_PLACES, SHOW_ROADS, HIDE_ROADS,
} from 'services/constants/map';
import {EventBus, EVENTS} from '@/event-bus';


export default {
    name: 'Map',
    components: {
        Btn,
        Icon,
        ToggleSwitch,
    },
    props: {
        showDisplay: {
            type: Boolean,
            default: false,
        },
        showZoom: {
            type: Boolean,
            default: false,
        },
        keepCenter: {
            type: Boolean,
            default: false,
        },
        center: {
            type: Object,
            default: null,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        mapInfo: {
            type: Object,
            default: () => null,
        },
    },
    data() {
        return {
            map: null,
            mapDisplayView: 'map',
            showRecenterButton: false,
        };
    },
    created() {
        this.OPTIONS = [
            {
                label: 'Carte',
                value: 'map',
            },
            {
                label: 'Satellite',
                value: 'satellite',
            },
        ];


        this.$nextTick(() => {
            // update
            this.$forceUpdate();
        });
    },
    watch: {
        center() {
            this.$nextTick(() => {
                if (this.center) {
                    // panTo = setCenter + animation
                    this.map.panTo({lat: this.center.lat, lng: this.center.lng});
                    this.$forceUpdate();
                }
            });
        },
        options() {
            this.$nextTick(() => {
                if (this.options) {
                    this.setOptions();
                    this.$forceUpdate();
                }
            });
        },
        keepCenter() {
            this.$nextTick(() => {
                this.setOptions();
                this.$forceUpdate();
            });
        }
    },
    mounted() {
        if (!window.google) {
            EventBus.$on(EVENTS.GOOGLE_MAP_IS_INIT, this.googleIsInit);
        } else {
            this.googleIsInit();
        }
    },
    beforeDestroy() {
        if (this.bikeLayer) this.bikeLayer.setMap(null);
        EventBus.$off(EVENTS.GOOGLE_MAP_IS_INIT, this.googleIsInit);
    },
    methods: {
        recenter() {
            if (this.center) {
                this.map.setCenter({lat: this.center.lat, lng: this.center.lng});
            }
        },
        zoomPlus: debounce(function () {
            const mapZoom = this.map.getZoom();
            const MAX_ZOOM = 22;
            if (mapZoom !== MAX_ZOOM) {
                this.map.setZoom(mapZoom + 1);
            }
        }, 100, {leading: true, trailing: false}),
        zoomMinus: debounce(function () {
            const mapZoom = this.map.getZoom();
            const MIN_ZOOM = 6;
            if (mapZoom !== MIN_ZOOM) {
                this.map.setZoom(mapZoom - 1);
            }
        }, 100, {leading: true, trailing: false}),
        googleIsInit() {

            this.map = new window.google.maps.Map(this.$refs.map, GOOGLE_MAP_SETTINGS);

            this.setOptions();

            if (this.center) {
                this.map.setCenter({lat: this.center.lat, lng: this.center.lng});
            }


            this.$emit('mapIsInit', this.map);
        },
        setOptions() {
            let self = this
            this.showRecenterButton = false;
            const options = merge({}, GOOGLE_MAP_SETTINGS, JSON.parse(JSON.stringify(this.options)));

            if (this.mapInfo) {
                if (this.mapInfo.showPlaces) {
                    options.styles.push(SHOW_PLACES);
                    options.clickableIcons = true;
                    this.showRecenterButton = true;
                } else {
                    options.styles.push(HIDE_PLACES);
                }
                if (this.mapInfo.showRoads) {
                    options.styles.push(SHOW_ROADS);
                } else {
                    options.styles.push(HIDE_ROADS);
                }

                // only if map
                if (this.map) {
                    if (this.mapInfo.showCyclingPath) {
                        this.bikeLayer = new window.google.maps.BicyclingLayer();
                        this.bikeLayer.setMap(this.map);
                    }
                }
            } else {
                options.styles.push(HIDE_PLACES);
                options.styles.push(SHOW_ROADS);
            }

            this.map.setOptions(options);

            if (this.mapInfo && this.map && !isEmpty(this.mapInfo.displayView)) {
                this.updateDisplay(this.mapInfo.displayView);
            }

            if (this.keepCenter) {
                let id = -1
                window.google.maps.event.addListener(this.map, 'center_changed', function (event) {
                    clearTimeout(id)
                    id = setTimeout(() => {
                        self.map.panTo({lat: self.center.lat, lng: self.center.lng})
                    }, 500);
                });
            }
        },
        updateDisplay(value) {
            if (value === 'satellite') {
                this.map.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
                this.mapDisplayView = 'satellite';
            } else {
                this.map.setMapTypeId(window.google.maps.MapTypeId.ROADMAP);
                this.mapDisplayView = 'map';
            }
        },
    },
};

</script>

<style lang="scss">

    .o-map {
        display: block;
        width: 100%;
        height: 0;
        padding-top: 100%;
        background-color: $color-grey-light;
        border-radius: $radius;

        @screen xs {
            padding-top: 75%;
        }

        @screen sm {
            padding-top: 50%;
        }

        @screen md {
            padding-top: 100%;
        }
    }

    .o-map__display {
        position: absolute;
        z-index: 1;
        top: -.6em;
        right: 1em;

        & + .o-map__zoom {
            top: 3em;
        }
    }

    .o-map__zoom, .o-map__recenter {
        z-index: 1;
        position: absolute;
        top: 1em;
        right: 1em;
        display: flex;
        flex-direction: column;

        .c-btn {
            display: flex;
            width: 2em;
            height: 2em;
            min-height: 0;
            padding: 0;
            box-shadow: none;

            &:first-child {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }

            &:last-child {
                border-top: $border-light;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
        }

        .c-btn__label {
            margin: auto;
            font-size: 1.2em;
        }
    }

    .o-map__recenter {
        top: 8.2em;

        .svg-marker {
            width: 20px;
            height: 20px;
        }

        .c-btn {
            border-radius: $radius;
        }

    }

    .o-map__inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }

    .o-map__googlemap {
        height: 100%;
        width: 100%;

        .gmnoprint,
        .gm-style-ccm,
        [rel="noopener"] {
            //THAT'S ILLEGAL => https://cloud.google.com/maps-platform/terms/
            display: none !important;
        }

        .gm-style-iw-d > div {
            padding: 10px;
        }

        .gm-style-iw.gm-style-iw-c .gm-ui-hover-effect {
            top: 0 !important;
            right: 0 !important;
        }
    }

</style>
