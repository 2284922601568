<template>
    <div class="o-toggle-switch">
        <div
            v-for="(option, i) in options"
            :key="option.value"
            class="o-toggle-switch__el"
        >
            <input
                :value="option.value"
                :name="name !== '' ? name : _uid"
                :checked="value_ === option.value"
                @change.prevent="setValue($event.target.value)"
                type="radio">
            <label>
                {{ option.label }}
            </label>
        </div>
    </div>
</template>

<script>
import { isEmpty } from 'services/utils';

export default {
    name: 'ToggleSwitch',
    props: {
        defaultValue: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            required: true,
        },
        name: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            value_: null,
        };
    },
    watch: {
        defaultValue(newValue) {
            if (!isEmpty(newValue) && newValue !== this.value_) {
                this.value_ = newValue;
            }
        },
        value(newValue) {
            if (!isEmpty(newValue) && newValue !== this.value_) {
                this.value_ = newValue;
            }
        },
    },
    created() {
        if (this.options.length > 0) {
            this.value_ = this.options[0].value;
        }

        if (!isEmpty(this.defaultValue)) {
            this.value_ = this.defaultValue;
        }

        if (!isEmpty(this.value)) {
            this.value_ = this.value;
        }
    },
    methods: {
        setValue(val) {
            this.value_ = val;
            this.$emit('toggleValue', val);
        },
    },
};
</script>


<style lang="scss">

    .o-toggle-switch {
        display: inline-flex;
        align-items: center;
        padding: .4em .7em;
        background-color: $color-light;
        border-radius: $radius;
        box-shadow: $shadow;

        &--md {
            padding-top: .5em;
            padding-bottom: .5em;

            .o-toggle-switch__el {

                label {
                    padding: .2em .7em;
                    font-size: .875em;
                    color: $color-dark;
                }
            }
        }
    }

    .o-toggle-switch__el {

        &:nth-child(n+2) {
            margin-left: .2em;
        }

        input {
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            cursor: pointer;
            opacity: 0;

            &:hover + label {
                color: $color-light;
                transition: color .2s $out-sine;

                &:before {
                    transform: scale(1);
                    // transform-origin: 0 50%;
                    transition: transform .5s $out-expo;
                }

                // &:after {
                //     opacity: 1;
                //     transition: opacity .3s $out-sine;
                // }
            }

            &:checked + label {
                color: $color-dark;
                transition: color .3s $out-sine;

                &:before {
                    opacity: 0;
                    transition: opacity .2s $in-sine;
                }

                &:after {
                    opacity: 1;
                    transform: scale(1);
                    transition: all .2s $out-sine .1s;
                }
            }
        }

        label {
            z-index: 1;
            display: block;
            padding: .5em 1em;
            font-size: .625em;
            font-weight: 700;
            color: $color-grey;
            text-transform: uppercase;
            border-radius: 2em;
            overflow: hidden;
            user-select: none;
            transition: color .2s $in-sine;

            &:after,
            &:before {
                z-index: -1;
                @include pseudo-el;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: inherit;
            }

            &:before {
                background-color: $color-grey;
                opacity: .3;
                transform: scale(0, 1);
                transition: transform .3s $in-expo;
            }

            &:after {
                border: 2px solid $color-dark;
                opacity: 0;
                transform: scale(.8);
                transition: all .2s $in-sine 0s;
            }
        }
    }

</style>
