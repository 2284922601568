<template>
    <LoadingContainer loaderName="logout"></LoadingContainer>
</template>

<script>
import types from 'store/types';
import LoadingContainer from 'templates/containers/LoadingContainer';

export default {
    name: 'logout',
    components: { LoadingContainer },
    created() {
        this.$store.dispatch(types.LOGOUT_USER);
    },
};
</script>
