<template>
    <div class="c-form__el">
        <strong
            v-if="!isEmpty(label)"
            class="t-s-grey">
            {{ label }}
        </strong>
        <div
            ref="el"
            class="o-input-text"
            :class="{ 'o-input-text--editable' : editable, 'o-input-text--show-save-btn': showSaveBtn, 'o-input-text--show-edit-btn': showEditBtn}"
        >
            <textarea
                v-if="multiLine"
                ref="input"
                :name="name"
                :id="_uid"
                placeholder=""
                :value="value"
                rows="1"
                @input.prevent="onChange">
            </textarea>

            <input
                v-else
                ref="input"
                :name="name"
                :id="_uid"
                :value="value"
                placeholder=""
                :type="type"
                @input.prevent="onChange">
            <label :for="_uid">
                <span
                    v-if="isEmpty(value)"
                    class="o-input-text__placeholder"
                >
                    {{ isEmpty(placeholder) ? label : placeholder }}
                </span>

                <span
                    v-if="editable || showEditBtn"
                    @click.stop="editInput"
                    class="o-input-text__set o-input-text__set--edit"
                >
                    <Icon icon="edit"/>
                    <span class="o-input-text__edit-text">Modifier</span>
                </span>
            </label>

            <Btn
                v-if="showSaveBtn || editable"
                iconBefore="check"
                label="sauvegarder"
                tag="span"
                class="o-input-text__set o-input-text__set--save"
                @click="saveInput"
            />
        </div>

        <transition name="anim-text">
            <p
                v-if="error"
                class="error"
            >
                {{ error }}
            </p>
        </transition>
    </div>
</template>

<script>
import Btn from 'templates/objects/Btn';
import Icon from 'templates/objects/Icon';

export default {
    name: 'InputText',
    components: {
        Btn,
        Icon,
    },
    props: {
        name: {
            type: String,
            default: 'input',
        },
        placeholder: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        error: {
            type: String,
        },
        value: {
            type: String,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        showSaveBtn: {
            type: Boolean,
            default: false,
        },
        showEditBtn: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        multiLine: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.textareaInputHeight();
    },
    created() {
        window.addEventListener('resize', this.textareaInputHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.textareaInputHeight);
    },
    methods: {
        textareaInputHeight() {
              if (this.multiLine) {
                  this.$nextTick(() => {
                      this.$refs.input.style.height = '20px';
                      if (this.$refs.input.scrollHeight !== 0) {
                          this.$refs.input.style.height = `${this.$refs.input.scrollHeight}px`;
                      }
                  })
              }
        },
        onChange(e) {
            this.$emit('change', e.target.value);
            this.$emit('input', e.target.value);

            this.textareaInputHeight();
        },
        editInput() {
            this.$emit('edit');
            this.$refs.input.select();
        },
        saveInput(e) {
            this.$emit('save', e);
        },
    },
};
</script>
