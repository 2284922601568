<template>
    <div class="p-set-password">
        <LoadingContainer loaderName="set-password">

            <h1 class="t-t2">Réinitialisation votre mot de passe</h1>
            <div class="c-form">

                <div class="mb-4">
                    <p v-if="loginName">Un courriel contenant un code spécial a éte envoyé à {{loginName}}.</p>
                    <p v-else>Un courriel contenant un code spécial vous a éte envoyé.</p>
                    <p>Veuillez entrer ce code dans le champ approprié avant de saisir votre nouveau mot de passe.</p>
                </div>

                <ResetPasswordForm
                    ref="setPasswordForm"
                    @submit="submitSetPassword"
                />

                <div class="c-form__btns">
                    <Link
                        label="Recommencer ?"
                        :href="ROUTES.MODAL.AUTH.FORGOT_PASSWORD"
                    />
                    <Btn
                        label="Changer mon mot de passe"
                        bgColor="yellow"
                        class="c-form__submit"
                        @click="submitSetPassword"
                    />
                </div>
            </div>
        </LoadingContainer>
    </div>
</template>

<script>
import types from 'store/types';
import { ROUTES } from 'config/routes';
import LoadingContainer from 'templates/containers/LoadingContainer';
import Btn from 'templates/objects/Btn';
import Link from 'templates/objects/Link';
import ResetPasswordForm from 'templates/modals/auth/forms/ResetPasswordForm';

export default {
    name: 'ResetPassword',
    components: {
        ResetPasswordForm, Link, Btn, LoadingContainer,
    },
    data() {
        return {
            loginName: '',
        };
    },
    created() {
        const query = this.$route.query || null;

        if (query) {
            if (typeof query.loginName !== 'undefined') {
                this.loginName = query.loginName;
            }
        }

        if (this.$store.state.user.currentUser !== null) {
            this.$store.dispatch(types.LOGOUT_USER, false);
        }
    },
    methods: {
        submitSetPassword(event) {
            if (event) event.preventDefault();
            this.$refs.setPasswordForm.submitSetPasswordForm()
                .then(() => {
                    this.$router.push(ROUTES.MODAL.AUTH.LOGIN);
                })
                .catch(() => {
                    // error
                });
        },
    },
};
</script>
